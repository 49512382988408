import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Box } from "rebass";

import Hero from "../../components/Hero";
import Divider from "../../components/Divider";
import Container from "../../components/Container";
import TextComponent from "../../components/Text";
import Contact from "../../components/Contact";
import Map from "../../components/Map";
import Post from "../../components/Posts";
import News from "../../components/News";
import Press from "../../components/Press";
import Reports from "../../components/Reports";
import TextImage from "../../components/TextImage";
import FinancialCalendar from "../../components/FinancialCalendar";

import { setActive } from "./Page.action";
import { fetchPageIfNeeded } from "./Page.action";
import { fetchFinancialCalendarIfNeeded } from "../FinancialCalendar/FinancialCalendar.action";
import YoastHead from "../../components/YoastHead";

const Fade = styled(Box)`
  opacity: ${(props) => (props?.active ? 1 : 0)};
  transition: opacity 220ms ease-in;
  transition-delay: 100ms;
`;

const components = {
  hero: Hero,
  divider: Divider,
  container: Container,
  text: TextComponent,
  contact: Contact,
  map: Map,
  cases: Post,
  news: News,
  press: Press,
  report: Reports,
  text_image: TextImage,
  financial_calendar: FinancialCalendar
};

const Page = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const path = params.page || params.path || "home";
  const page = useSelector((state) => {
    return state.entities.pages[path];
  });
  const acfLang = useSelector((state) => state.app.acfLang);

  useEffect(() => {
    dispatch(fetchPageIfNeeded(path))
      .then(() => {
        if (page?.title?.rendered !== "Cookies") {
            dispatch(setActive(page, acfLang))
        }
      })
      .catch(() => {
        history.push("/");
      });

      dispatch(fetchFinancialCalendarIfNeeded())
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const [cbConsent, setCbConsent] = useState(false);

  return (
    <div className={"page-view"}>
        <YoastHead json={ page?.yoast_head } />
        <Fade active={page?.acf}>
        {page?.acf &&
            page.acf[acfLang] &&
            page.acf[acfLang].map((block, i) => {
                const BlockComponent = components[block.acf_fc_layout];
            return BlockComponent ? (
                <BlockComponent key={i} {...block} page={page} cbConsent={cbConsent} setCbConsent={setCbConsent} />
            ) : null;
            })}
        </Fade>
    </div>
  );
};

export default Page;
