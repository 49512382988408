import request from "../../api/request";
import * as types from "./Disclaimer.constant";

function requestDisclaimer() {
  return {
    type: types.FETCH_DISCLAIMER_REQUEST,

  };
}

function shouldFetchDisclaimer(state) {
  return state.disclaimer?.data?.length === 0 && !state.disclaimer.isFetching
}

export function fetchDisclaimerIfNeeded() {
  return (dispatch, getState) => {
    return shouldFetchDisclaimer(getState())
      ? dispatch(fetchDisclaimer())
      : Promise.resolve();
  };
}

function receiveDisclaimer(response) {
  return {
    type: types.FETCH_DISCLAIMER_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

export function fetchDisclaimer() {
  return dispatch => {
    dispatch(requestDisclaimer());

    return request(`/theme-settings/disclaimer-data`).then(json =>{
       dispatch(receiveDisclaimer(json))
      }
    ).catch((error) => {
      console.log(error)
      dispatch(
      {
        type: types.FETCH_DISCLAIMER_FAILURE,
        receivedAt: Date.now()
      });
    });
  }
}
