import React from "react";
import { NavLink } from "react-router-dom";
import { Text, Box, Flex } from "rebass";
import Container from "../../ui/Container";
import { CustomLink } from "../Header";

const InvestorRelationsMenuDesktop = ({
  route,
  irMenu,
  onMenuEnter,
  onMenuOut,
  lang,
}) => (
  <Box
    bg={["#020D1D"]}
    color={"#E6E9ED"}
    sx={{
      position: "fixed",
      top: "52px",
      left: "0px",
      right: "0px",
      zIndex: 20,
    }}
    onMouseEnter={() => onMenuEnter()}
    onMouseLeave={() => onMenuOut()}
  >
    <Container pt={10}>
      <Box width={[9 / 12]} mx="auto" px={2} pb={6}>
        <Flex
          sx={{
            alignItems: "flex-start",
            justifyContent: "center",
          }}
          flexWrap="wrap"
        >
          {irMenu &&
            irMenu.map((page, i) => (
              <Box width={[1 / 3, 1 / 3, 1 / 5]} key={i} mb="4" pr={4}>
                <Text mb="3" fontWeight="bold">
                  <NavLink
                    to={page.url}
                    key={page.title}
                    style={{ color: "#E6E9ED" }}
                  >
                    <CustomLink>{page.title[lang]}</CustomLink>
                  </NavLink>
                </Text>
                {page.children &&
                  page.children.map((subpage, n) => (
                    <Text key={n} mb="2">
                      <NavLink
                        to={subpage.url}
                        active={route === subpage?.url ? "true" : undefined }
                        key={subpage.title[lang]}
                        style={{ color: "#E6E9ED" }}
                        activeStyle={{
                          borderBottom: "3px solid #E6E9ED",
                        }}
                      >
                        {subpage.title[lang]}
                      </NavLink>
                    </Text>
                  ))}
              </Box>
            ))}
        </Flex>
      </Box>
    </Container>
  </Box>
);

export default InvestorRelationsMenuDesktop;
