import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createBrowserHistory } from "history";

import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export default function configureStore() {
  const middleware = [thunk, routerMiddleware(history)];
  if (process.env.NODE_ENV !== "production") {
    middleware.push(logger);
  }

  const store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(...middleware),
      typeof window === "object" &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
