import React from "react";
import { useSelector } from "react-redux";
import { Button, Text } from "rebass";
import styled, { keyframes } from 'styled-components';
import CalendarCheckIcon from "../../assets/icons/calendarCheck";

const scale = keyframes`
  to {
    transform: scale(1);
  }
`;

const ButtonAnimation = styled(Button)`
  animation: ${scale} .25s forwards;
  transform: scale(0);
`;
const buttonLabel = {'eng': "Book a meeting", 'swe': "Boka ett möte"};

const FloatButton = () => {
  const lang = useSelector((state) => state.app.lang);
  return (
    <ButtonAnimation
      bg="primary"
      href="https://boka.sonat.se/#boka"
      variant="primary"
      as="a"
      sx={{
        position: "fixed",
        bottom: "35px",
        right: "35px",
        boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.4)",
        transformOrigin: "bottom right",
        animationDelay: "2s",
        zIndex:"9998",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
      }}
    >
      <CalendarCheckIcon m={10}/>
      <Text ml={2} dangerouslySetInnerHTML={{ __html: buttonLabel[lang]}} />
    </ButtonAnimation>
  );
};

export default FloatButton;
