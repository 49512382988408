import request from "../../api/request";
import * as types from "./App.constant";

export const setLang = (lang) => (dispatch) =>
  dispatch({
    type: types.APP_SET_LANG,
    response: { lang },
  });

export const setACFLang = (acfLang) => (dispatch) => {
  dispatch({
    type: types.APP_SET_ACF_LANG,
    response: { acfLang },
  });
};

export const setLoadingState = (isLoading) => (dispatch) =>
  dispatch({
    type: types.APP_SET_LOADING_STATE,
    response: { isLoading },
  });

export const toggleMenu = (active) => (dispatch) => {
  return dispatch({
    type: types.SET_MOBILE_STATE,
    response: { active },
  });
};

export const toggleSearch = (active) => (dispatch) => {
  return dispatch({
    type: types.SET_SEARCH_STATE,
    response: { active },
  });
};

function shouldFetchMenu(state, slug) {
  //const menu = state.app.menus[slug];
  return true; //!menu;
}

export function fetchMenuIfNeeded(slug) {
  return (dispatch, getState) => {
    return shouldFetchMenu(getState(), slug)
      ? dispatch(fetchMenu(slug))
      : Promise.resolve();
  };
}

function requestMenu(slug) {
  return {
    type: types.FETCH_MENU_REQUEST,
    slug,
  };
}

function receiveMenu(response) {
  return {
    type: types.FETCH_MENU_SUCCESS,
    response,
    receivedAt: Date.now(),
  };
}

function fetchMenu(slug) {
  return (dispatch) => {
    dispatch(requestMenu(slug));
    return request(`/menus/${slug}`, false, false).then((json) => {
      return dispatch(receiveMenu({ [slug]: json }));
    });
  };
}
