import React, { useState } from "react";
import { Flex, Box, Text, Button } from "rebass";
import { Input } from "@rebass/forms";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { searchPosts } from "../containers/Post/Post.action";
import { toggleSearch } from "../containers/App/App.action";

const Overlay = styled(Box)`
  position: fixed;
  top: 52px;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 99;
`;

const SearchInput = styled(Input)`
  border-style: none;
  border-bottom: 1px solid white;
`;

export default () => {
  const [searchText, setsearchText] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useSelector((state) => state.app.lang);
  const tagsList = useSelector((state) => state.entities.tags);
  const posts = useSelector((state) =>
    state.post.search.map((slug) => state.entities.posts[slug])
  );
  const searchActive = useSelector((state) => state.app.searchActive);
  const onEnter = (event) =>
    event.key === "Enter" && dispatch(searchPosts(searchText));
  return searchActive ? (
    <Overlay width={[1, 2 / 3, 1 / 2]}>
      <Box height="100%">
        <Flex flexWrap="wrap" pt={5} pb={2} mx={5}>
          <Box
            flex={1}
            pr={2}
            sx={{
              "min-width": ["100%", "auto"],
            }}
          >
            <SearchInput
              sx={{
                borderStyle: "none",
                borderBottom: "1px solid white",
                color: "white",
                paddingLeft: 0,
              }}
              id="search"
              value={searchText}
              onChange={(event) => setsearchText(event.target.value)}
              onKeyPress={(e) => onEnter(e)}
              name="search"
              type="text"
              placeholder={t("search")}
            />
          </Box>
          <Box pr={2} pt={[2, 0]}>
            <Button
              onClick={() => {
                dispatch(searchPosts(searchText));
              }}
            >
              {t("search")}
            </Button>
          </Box>
          <Box pt={[2, 0]}>
            <Button
              px={4}
              onClick={() => {
                dispatch(toggleSearch(!searchActive));
              }}
            >
              {t("close")}
            </Button>
          </Box>
        </Flex>
        <Box
          mx={5}
          sx={{
            height: "calc(100% - 80px)",
            overflow: "auto",
          }}
        >
          <Flex flexWrap="wrap">
            {posts.map(({ acf, slug, tags }, i) => (
              <Box key={i} py={4} width={1} sx={{ borderBottom: "1px solid white" }}>
                <Text
                  variant="h4"
                  color="white"
                  mb={1}
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  {tags &&
                    tags.map((tag) => tagsList[tag].slug.replace(/-/gi, " "))}
                </Text>
                <Text
                  variant="paragraph"
                  color="white"
                  mb={2}
                  width={[1, 1, 1 / 2]}
                >
                  {truncate(acf.preview[lang], {
                    length: 130,
                    omission: "...",
                  })}
                </Text>
                <Text
                  fontWeight="bold"
                  color="white"
                  variant="paragraph"
                  sx={{
                    textDecoration: "underline",
                  }}
                  mb={1}
                  as={Link}
                  to={"/posts/" + slug}
                  onClick={() => {
                    dispatch(toggleSearch(!searchActive));
                  }}
                >
                  {t("read more")}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
    </Overlay>
  ) : null;
};
