import { union } from "lodash";

import {
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_FAILURE,
  FETCH_PAGES_REQUEST,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_FAILURE,
  SET_PAGE_ACTIVE
} from "./Page.constant";

export default function pages(
  state = {
    isFetching: false,
    pagesByIds: [],
    activePage: null,
    pageTitle: null
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_PAGES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_PAGES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        pagesByIds: union(state.pagesByIds, action.response.result)
      });
    case FETCH_PAGES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    case FETCH_PAGE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_PAGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        pagesByIds: union(state.pagesByIds, [action.response.result])
      });
    case FETCH_PAGE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
      case SET_PAGE_ACTIVE:
        return Object.assign({}, state, {
          activePage: action.response.page,
          pageTitle:  action.response.title
        });
    default:
      return state;
  }
}
