import Schemas from "../../api/schemas";
import request from "../../api/request";
import * as types from "./Page.constant";

function requestPage(slug) {
  return {
    type: types.FETCH_PAGE_REQUEST,
    slug,
  };
}

function receivePage(response) {
  return {
    type: types.FETCH_PAGE_SUCCESS,
    response,
    receivedAt: Date.now(),
  };
}

function fetchPage(slug) {
  return (dispatch) => {
    dispatch(requestPage(slug));
    return request(`/pages/?slug=${slug}`, Schemas.PAGE, true).then((json) =>
      dispatch(receivePage(json))
    );
  };
}

function shouldFetchPage(state, slug) {
  const page = state.entities.pages[slug];
  return !(page && page.content && page.acf && page.acf.eng);
}

export function fetchPageIfNeeded(slug) {
  return (dispatch, getState) => {
    return shouldFetchPage(getState(), slug)
      ? dispatch(fetchPage(slug))
      : Promise.resolve();
  };
}
/*

*/

function requestPages() {
  return {
    type: types.FETCH_PAGES_REQUEST,
  };
}

function receivePages(response) {
  return {
    type: types.FETCH_PAGES_SUCCESS,
    response,
    receivedAt: Date.now(),
  };
}
function fetchPages(lang) {
  return (dispatch) => {
    dispatch(requestPages());
    const p = `?per_page=100&`;
    const filter = lang
      ? `?${lang}`
      : "?fields=id,date,slug,status,type,title,link,menu_order,acf.title";
    return request(`/pages/${p}${filter}`, Schemas.PAGE_ARRAY).then((json) => {
      return dispatch(receivePages(json));
    });
  };
}

export function fetchPagesIfNeeded(lang) {
  return (dispatch, getState) => {
    if (!getState().page.pagesByIds.length) {
      return dispatch(fetchPages(lang));
    }
  };
}

export function setActive(currentPage, lang) {
  let pageTitle = currentPage ? currentPage.acf.title[lang].trim() : "";
  return {
    type: types.SET_PAGE_ACTIVE,
    response: {
      page: currentPage,
      title: pageTitle,
    },
    receivedAt: Date.now(),
  };
}
