import React, { useEffect } from "react";
import { Text, Box, Flex, Card, Image } from "rebass";
import { useDispatch, useSelector } from "react-redux";
import { truncate, chunk, round } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { fetchPostsIfNeeded } from "../containers/Post/Post.action";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const News = ({ background, title, posts, tagsList, lang, t }) => (
  <Wrapper size="small" bg={background} color={getTextColor(background)}>
    <Container>
      <Grid pb={4}>
        <Text variant="paragraph" fontWeight="bold">
          {title}
        </Text>
      </Grid>
      <Flex
        sx={{
          flexWrap: "wrap",
        }}
      >
        {posts.map(({ acf, slug, tags }) => (
          <Grid as={Card} key={slug} width={[1, 1 / 2, 1 / 4]}>
            {acf && acf.feature_image && acf.feature_image.url && (
              <Image mb={1} src={acf.feature_image.url} />
            )}
            <Text
              variant="h4"
              mb={1}
              sx={{
                textTransform: "uppercase",
              }}
            >
              {tags &&
                tags.map((tag) => tagsList[tag].slug.replace(/-/gi, " "))}
            </Text>
            <Text variant="paragraph" mb={2}>
              {truncate(acf.preview[lang], { length: 130, omission: "..." })}
            </Text>
            <Text
              fontWeight="bold"
              variant="paragraph"
              sx={{
                textDecoration: "underline",
              }}
              mb={1}
              as={Link}
              to={"/posts/" + slug}
            >
              {t("read more")}
            </Text>
          </Grid>
        ))}
      </Flex>
    </Container>
  </Wrapper>
);
/*
// <Grid width={[1, 1 / 2]} pr={[0, i % 2 === 0 ? 12 : 1]} pb={8}>

// </Grid>
*/
const List = ({ background, title, posts, t, lang }) => {
  const newPosts = chunk(posts, round(posts.length / 2));

  return (
    <Wrapper size="large" bg={background}>
      <Container>
        <Grid pb={4}>
          <Text variant="h2" fontWeight="normal">
            {title}
          </Text>
        </Grid>
        <Flex flexWrap="wrap">
          {newPosts.map((p, i) => {
            return (
              <Box key={i} width={[1, 1, 1 / 2]}>
                {p.map(({ acf, slug }) => (
                  <Box
                    key={slug}
                    px={2}
                    width={i === 0 ? [1, 1, 9 / 12] : [1]}
                    pb={[5, 5, 11]}
                  >
                    <Image
                      pb={2}
                      src={acf.feature_image && acf.feature_image.url}
                    />
                    <Text pb={2} variant="paragraph">
                      {truncate(acf.preview[lang], {
                        length: 130,
                        omission: "...",
                      })}{" "}
                    </Text>
                    <Text
                      as={Link}
                      to={"/posts/" + slug}
                      variant="paragraph"
                      fontWeight="bold"
                      sx={{
                        textDecoration: "underline",
                      }}
                    >
                      {t("read more")}
                    </Text>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Flex>
      </Container>
    </Wrapper>
  );
};

const components = {
  news: News,
  list: List,
};

const Cases = ({ cases = [], background, display = "news", title }) => {
  const casesSlugs = cases ? cases.map((c) => c.post_name) : [];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useSelector((state) => state.app.lang);
  const isFetching = useSelector((state) => state.post.isFetching);
  const posts = useSelector((state) =>
    casesSlugs.map((slug) => state.entities.posts[slug]).filter(Boolean)
  );
  const tagsList = useSelector((state) => state.entities.tags);

  useEffect(() => {
    dispatch(
      fetchPostsIfNeeded(null, {
        slugs: casesSlugs.join(","),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching || !posts) return null;

  const Component = components[display];

  return (
    <Component
      posts={posts}
      background={background}
      title={title}
      tagsList={tagsList}
      t={t}
      lang={lang}
    />
  );
};

export default Cases;
