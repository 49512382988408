import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass";
import i18next from "i18next";
import { Helmet } from "react-helmet";

import Routes from "../../config/routes";

import { fetchMenuIfNeeded, setLoadingState, toggleMenu, setACFLang } from "./App.action";
import { fetchPagesIfNeeded } from "../Page/Page.action";
import { fetchPeopleIfNeeded } from "../Person/Person.action";
import { fetchTagsIfNeeded } from "../Tag/Tag.action";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Marquee from "../../components/Marquee";
import Search from "../../components/Search";
import Banner from "../../ui/parts/Banner";

const App = (props) => {
  const lang = useSelector((state) => state.app.lang);
  const pageTitle = useSelector((state) => state.page.pageTitle);
  const postTitle = useSelector((state) => state.post.postTitle);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const acfLang = useSelector((state) => state.app.acfLang);

  const trackLeadfeeder = (title) => {
    if (title && window.ldfdr) {
      window.ldfdr.pageview({
        pageUrl: document.location.href,
        pageTitle: title,
      });
    }
  };

  useEffect(() => {
    trackLeadfeeder(postTitle);
  }, [postTitle]);

  useEffect(() => {
    trackLeadfeeder(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    dispatch(setLoadingState(true));
    Promise.all([
      dispatch(fetchPagesIfNeeded(lang)),
      dispatch(fetchPeopleIfNeeded()),
      dispatch(fetchTagsIfNeeded()),
      dispatch(fetchMenuIfNeeded("ir")),
      dispatch(fetchMenuIfNeeded("primary")),
    ]).then(() => dispatch(setLoadingState(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setACFLang(lang));
    window.scrollTo(0, 0);
    dispatch(toggleMenu(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  useEffect(() => {
    dispatch(fetchPagesIfNeeded(lang));
    i18next.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  if (isLoading) return null;
  return (
    <Box position="relative">
      <Helmet htmlAttributes={{ lang: lang === "swe" ? "sv" : "en" }}>
        <title>{pageTitle}</title>
      </Helmet>
      <Search />
      <Header {...props} />
      <Box pt={["62px", "62px", "62px", "52px"]}>
        {acfLang !== lang && <Banner />}
        <Routes />
      </Box>
      <Footer />
      <Marquee />
    </Box>
  );
};

export default withRouter(App);
