import { schema } from "normalizr";

const pageSchema = new schema.Entity("pages", {}, { idAttribute: "slug" });

const postSchema = new schema.Entity("posts", {}, { idAttribute: "slug" });

const mfnSchema = new schema.Entity("mfns", {}, { idAttribute: "slug" });

const menuSchema = new schema.Entity("menus");

const tagSchema = new schema.Entity("tags");

const financialCalendarSchema = new schema.Entity("financialCalendar");

const personSchema = new schema.Entity("people", {}, { idAttribute: "slug" });

export default {
  PAGE: pageSchema,
  PAGE_ARRAY: [pageSchema],
  MFN: mfnSchema,
  MFN_ARRAY: [mfnSchema],
  POST: postSchema,
  POST_ARRAY: [postSchema],
  TAG_ARRAY: [tagSchema],
  PERSON_ARRAY: [personSchema],
  MENU: menuSchema,
  MENU_ARRAY: [menuSchema],
  FINANCIAL_CALENDAR: [financialCalendarSchema]
};
