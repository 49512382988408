import React, { useState } from "react";
import { Text, Box, Flex, Image } from "rebass";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Wrapper from "../ui/Wrapper";
import ContainerComp from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";
import ReactPlayer from 'react-player/youtube'

const Stories = ({
  background,
  headline,
  sub_headline,
  paragraph,
  link,
  custom_link,
  news_block_video,
  news_block_head_type,
  t,
}) => {

const handlePlay =()=>{
  setIsPlaying(true)
}
const [isPlaying, setIsPlaying] = useState(false);
  return (
  <Grid width={[1 / 1, 1 / 2]} mb={[4, 4, 0]}>
    <Box
    classID="image"
      color="white"
      mb={3}
      sx={{
        backgroundImage: news_block_head_type === "image" && background ? `url(${background.url})`: false,
        backgroundSize: "cover",
        overflow: "hidden",
        height: 0,
        paddingTop: "66.25%",
        position: "relative",
      }}
    >
      {news_block_head_type === "video" && (
        <Box
          className="player"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer"
          }}
        >
          <ReactPlayer
            url={news_block_video}
            width="100%"
            height="100%"
            controls={true}
            onPlay={handlePlay}
            onPause={()=>setIsPlaying(false)}
          />
          {!isPlaying &&
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor:"rgba(0,0,0,0.5)",
                pointerEvents:"none",
                cursor: "pointer"
              }}
            />
          }
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translate(0% , -50%)",
          padding: "0 30px",
          textAlign: "center",
          width: "100%",
        }}
      >
        {headline && headline.length > 0 && news_block_head_type !== "video" && (
          <Text variant="h2">{headline}</Text>
        )}

        {headline && headline.length > 0 && news_block_head_type === "video" && !isPlaying && (
          <Text
            color={"white"}
            variant="h2"
            sx={{
              cursor: "pointer",
              pointerEvents: "none"
            }}
          >
            {headline}
          </Text>
        )}
      </Box>
    </Box>
    {sub_headline && sub_headline.length > 0 && (
      <Text variant="paragraph" pb={4} fontWeight="bold">
        {sub_headline}
      </Text>
    )}
    <Text variant="paragraph" pb={2}>
      {paragraph}
    </Text>
    {link && (
      <Text
        fontWeight="bold"
        variant="paragraph"
        sx={{
          textDecoration: "underline",
        }}
      >
        <Link to={link.post_name} style={{ color: "inherit" }}>
          {t("view")}
        </Link>
      </Text>
    )}
    {custom_link && (
      <Text
        fontWeight="bold"
        variant="paragraph"
        sx={{
          textDecoration: "underline",
        }}
      >
        <a href={custom_link.destination}>{custom_link.title}</a>
      </Text>
    )}
  </Grid>
  );
};

const Promo = ({ background, headline, sub_headline }) => (
  <Grid width={1 / 1}>
    <Box
      mb={2}
      sx={{
        backgroundImage: `url(${background && background.url})`,
        backgroundSize: "cover",
        overflow: "hidden",
        height: 0,
        paddingTop: "46%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translate(0% , -50%)",
          padding: "0 30px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Text variant="h1">{headline}</Text>
        <Text variant="paragraph" pb={4}>
          {sub_headline}
        </Text>
      </Box>
    </Box>
  </Grid>
);

const Cert = ({ background, paragraph, headline }) => (
  <Grid width={[1, 1, 1 / 2, 1 / 4]}>
    {background && background.url && <Image mb={1} src={background.url} />}
    <Text variant="paragraph" fontWeight="bold" mb={1}>
      {headline}
    </Text>
    <Text variant="paragraph" mb={2}>
      {paragraph}
    </Text>
  </Grid>
);

const components = {
  stories: Stories,
  promo: Promo,
  cert: Cert,
};

const Container = ({ display, title, background, news_block = [] }) => {
  const { t } = useTranslation();
  return (
    <Wrapper
      size="large"
      bg={background}
      color={getTextColor(background)}
      key={title + Math.random()}
    >
      <ContainerComp>
        {title.length > 0 && display !== "cert" && (
          <Grid pb={4}>
            <Text variant="paragraph" fontWeight="bold">
              {title}
            </Text>
          </Grid>
        )}
        <Flex flexWrap="wrap">
          {title.length > 0 && display === "cert" && (
            <Grid width={[1, 1, 1 / 2, 1 / 4]} pb={5}>
              <Text variant="h2">{title}</Text>
            </Grid>
          )}
          {news_block.map((block, i) => {
            const BlockComponent = components[display];
            return BlockComponent ? (
              <BlockComponent key={i} {...block} title={title} t={t} />
            ) : null;
          })}
        </Flex>
      </ContainerComp>
    </Wrapper>
  );
};

export default Container;
