import React from "react";
import { Text, Button, Box, Flex, Image, Link as ExternalLink } from "rebass";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PdfButton from "../ui/parts/PdfButton";
import { getLinkProps } from "../utils";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const Cta = ({ text, button_link }) => (
  <React.Fragment>
    <Grid width={[1, 2 / 3]}>
      <Text variant="h2">{text}</Text>
    </Grid>
    {button_link && (
      <Grid width={[1, 1 / 3]} textAlign={["left", "right"]}>
        {button_link["url"] && isPDF(button_link["url"]) ? (
          <PdfButton link={button_link} />
        ) : (
          <Button
            {...getLinkProps({
              as: Link,
              to: button_link["url"],
              variant: "primary",
            })}
          >
            {button_link["title"]}
          </Button>
        )}
      </Grid>
    )}
  </React.Fragment>
);

const Person = ({ person }) => {
  const isFetching = useSelector((state) => state.person.isFetching);
  const p = useSelector((state) => state.entities.people[person.post_name]);
  const lang = useSelector((state) => state.app.lang);

  if (!p || isFetching || !p.acf) return null;

  const { name, title, email, phone, image } = p.acf;

  return (
    <Flex
      width="100%"
      flexWrap="wrap"
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid width={[1, 3 / 24]}>
        {image && image.url && <Image src={image.url} />}
      </Grid>
      <Grid width={[1, 9 / 24]}>
        <Box>
          <Text variant="paragraph" fontWeight="bold">
            {name}
          </Text>
          <Text variant="paragraph" fontWeight="bold">
            {title[lang]}
          </Text>
        </Box>
      </Grid>
      <Grid width={[1, 6 / 12]}>
        <Text variant="paragraph" fontWeight="bold">
          <ExternalLink color="inherit" variant="link" href={`mailto:${email}`}>
            {email}
          </ExternalLink>
        </Text>
        <Text variant="paragraph" fontWeight="bold">
          <ExternalLink color="inherit" variant="link" href={`tel:${phone}`}>
            {phone}
          </ExternalLink>
        </Text>
      </Grid>
    </Flex>
  );
};

const components = {
  cta: Cta,
  person: Person,
};

const Divider = ({ type = [], background }) => {
  return (
    <Wrapper size="xsmall" bg={background} color={getTextColor(background)}>
      <Container>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          {type &&
            type.map((block, i) => {
              const BlockComponent = components[block.acf_fc_layout];
              return BlockComponent ? (
                <BlockComponent key={i} {...block} />
              ) : null;
            })}
        </Flex>
      </Container>
    </Wrapper>
  );
};

function isPDF(url) {
  return url.endsWith(".pdf");
}

export default Divider;
