import React from "react";
import { NavLink } from "react-router-dom";
import { Text, Box } from "rebass";
import Container from "../../ui/Container";
import { CustomLink } from "../Header";

const InvestorRelationsMenuMobile = ({ route, irMenu, lang }) => (
  <Box bg={["#020D1D"]} color={"#E6E9ED"} width="100%">
    <Container px="18px" pt={5}>
      {irMenu &&
        irMenu.map((page, i) => (
          <Box key={i} mb="6">
            <Text mb="2" fontWeight="bold">
              <NavLink
                to={page.url}
                key={page.title}
                style={{ color: "#E6E9ED" }}
              >
                <CustomLink>{page.title[lang]}</CustomLink>
              </NavLink>
            </Text>
            {page.children &&
              page.children.map((subpage, n) => (
                <Text key={n} ml="2" mb="2">
                  <NavLink
                    to={subpage.url}
                    active={route === subpage?.url ? "true" : undefined }
                    key={subpage.title[lang]}
                    style={{ color: "#E6E9ED" }}
                    activeStyle={{
                      borderBottom: "3px solid #E6E9ED",
                    }}
                  >
                    {subpage.title[lang]}
                  </NavLink>
                </Text>
              ))}
          </Box>
        ))}
    </Container>
  </Box>
);

export default InvestorRelationsMenuMobile;
