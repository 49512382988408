import {
  FETCH_FINANCIAL_CALENDAR_REQUEST,
  FETCH_FINANCIAL_CALENDAR_SUCCESS,
  FETCH_FINANCIAL_CALENDAR_FAILURE,
} from "./FinancialCalendar.constant";

export default function financialCalendar(
  state = {
    isFetching: false,
    title: "",
    items: [],

  },
  action = {}
) {
  switch (action.type) {
    case FETCH_FINANCIAL_CALENDAR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_FINANCIAL_CALENDAR_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        title: action.response.title,
        items: action.response.items
      });
    case FETCH_FINANCIAL_CALENDAR_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
