import React from "react";

import { Link } from "react-router-dom";
import { Text, Button, Box } from "rebass";

import Container from "../ui/Container";

const HeroComponent = ({
  background,
  headline,
  paragraph,
  button,
  button_text
}) => {
  if (!background) return null;
  return (
    <Box
      sx={{
        px: 5,
        py: 9,
        background: `url(${background.url})  no-repeat center center`,
        backgroundSize: "cover",
        color: "white",
        bg: "gray",
        minHeight: ["60vh", "75vh"],
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}
    >
      <Container>
        <Box px={[2, 6]}>
          <Text variant="h1" textAlign="center">
            {headline}
          </Text>
        </Box>
        <Box width={[1, 2 / 3]} mx="auto">
          <Box pb={5}>
            <Text variant="paragraph" textAlign="center">
              {paragraph}
            </Text>
          </Box>
          {button_text && button && (
            <Box textAlign="center">
              <Button
                as={Link}
                to={"/posts/" + button.post_name}
                variant="primary"
              >
                {button_text}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default HeroComponent;
