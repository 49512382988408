import { union } from "lodash";

import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE
} from "./Tag.constant";

export default function tags(
  state = {
    isFetching: false,
    tagsByIds: []
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_TAGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_TAGS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        tagsByIds: union(state.tagsByIds, action.response.result)
      });
    case FETCH_TAGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
