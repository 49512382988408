import Schemas from "../../api/schemas";
import request from "../../api/request";
import * as types from "./Person.constant";

function requestPeople() {
  return {
    type: types.FETCH_PEOPLE_REQUEST
  };
}

function receivePeople(response) {
  return {
    type: types.FETCH_PEOPLE_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchPeople(f) {
  return dispatch => {
    dispatch(requestPeople());
    const filter = f
      ? `?${f}`
      : "?fields=id,date,slug,status,type,title,link,menu_order,acf&per_page=100";
    return request(`/people/${filter}`, Schemas.PERSON_ARRAY).then(json => {
      return dispatch(receivePeople(json));
    });
  };
}

export function fetchPeopleIfNeeded(filter) {
  return (dispatch, getState) => {
    if (!getState().person.peopleByIds.length) {
      return dispatch(fetchPeople(filter));
    }
  };
}
