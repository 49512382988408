import React from "react";
import { Text, Box, Flex, Image, Link } from "rebass";
import { getTextColor } from "../helpers";

import Container from "../ui/Container";
import Grid from "../ui/Grid";

const Map = ({ locations = [], background }) => (
  <Box bg={background} color={getTextColor(background)}>
    <Container>
      <Flex py={9} flexWrap="wrap">
        {locations.map(
          ({ map, name, address, zip_code, phone, email, streetview }, i) => (
            <Grid key={i} width={[1, 1, 1 / 2]}>
              <Text variant="h2" pb={3}>
                {name}
              </Text>
              {map && map.url && <Image src={map.url} pb={5} />}
              <Flex>
                <Box width={1 / 2}>
                  {streetview && streetview.url && (
                    <Image src={streetview.url} pr={4} />
                  )}
                </Box>
                <Box width={1 / 2}>
                  <Text variant="paragraph">{address}</Text>
                  <Text variant="paragraph">{zip_code}</Text>
                  <Text variant="paragraph">
                    Tel:{" "}
                    <Link color="inherit" variant="link" href={`tel:${phone}`}>
                      {phone}
                    </Link>
                  </Text>
                  <Text variant="paragraph">
                    E-post:{" "}
                    <Link color="inherit" href={`mailto:${email}`}>
                      {email}
                    </Link>
                  </Text>
                </Box>
              </Flex>
            </Grid>
          )
        )}
      </Flex>
    </Container>
  </Box>
);

export default Map;
