import React from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";

import Page from "../containers/Page/Page";
import Post from "../containers/Post/Post";
import Mfn from "../containers/Mfn/Mfn";
import Disclaimer from "../../src/ui/parts/Disclaimer";

export default () => {
  const params = useParams();
  const path = params.page || params.path || "home";
  return (
    <Switch>
      <Redirect exact from="/nyheter" to="/news" />
      <Redirect exact from="/om-oss" to="/about-us" />
      <Redirect exact from="/kontakt" to="/contact" />

      <IRGuardRoute
        exact
        key={path}
        path="/investor-relations/borsnotering/:page?"
        component={(data) => <Page {...data} />}
      />

      <Route
        exact
        path="/posts/:path?"
        component={(data) => <Post {...data} />}
      />
      <Route
        exact
        path="/investor-relations/pressmeddelanden/:path?"
        component={(data) => <Mfn {...data} />}
      />
      <Route
        key={path}
        path="/:path?/:page?"
        exact
        component={(data) => <Page {...data} />}
      />
      <Route
        key={path}
        path="/:path?/:page?/:page?"
        exact
        component={(data) => <Page {...data} />}
      />
    </Switch>
  );
};

const IRGuardRoute = ({ component: Component, ...props }) => {
  const item = localStorage.getItem("Sonat-IR-disclaimer");
  if (item !== null) {
    return (
      <Route
        key={props.path}
        path="/:path?/:page?/:page?"
        exact
        component={Component}
      />
    );
  }
  return <Route {...props} render={() => <Disclaimer />} />;
};
