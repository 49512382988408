import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { map } from "lodash";
import { Text, Box, Flex, Button } from "rebass";
import { useTranslation } from "react-i18next";
import { getLinkProps, getTag } from "../utils";

import { fetchPaginationMfnsIfNeeded } from "../containers/Mfn/Mfn.action";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const Press = ({ title, title_link, how_many_results, background }) => {
  const dispatch = useDispatch();
  const [filterType, setFilterType] = useState("all");
  const params = useParams();
  const limit = how_many_results ? parseInt(how_many_results) : 100;
  const lang = useSelector((state) => state.app.lang);

  const page = params.pageCount || 1;
  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.page.isFetching);

  const mfns = useSelector((state) => {
    return map(state.mfn.pagination[page], (slug) => {
      return state.entities.mfns[slug];
    });
  });

  useEffect(() => {
    dispatch(fetchPaginationMfnsIfNeeded(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!mfns || mfns.length < 1 || isFetching) return null;

  return (
    <Wrapper
      pt={8}
      pb="60px"
      size="small"
      bg={background}
      color={getTextColor(background)}
    >
      <Container>
        <Flex
          flexWrap="wrap"
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          {title_link?.url ? (
            <Text
              {...getLinkProps({
                as: Link,
                to: title_link?.url,
                variant: "h2",
                sx: {
                  textTransform: "uppercase",
                  color: "inherit",
                },
                mb: 4,
                textAlign: "center",
              })}
            >
              {title}
            </Text>
          ) : (
            <Text
              variant="h2"
              textAlign="center"
              mb={4}
              sx={{ textTransform: "uppercase" }}
            >
              {title}
            </Text>
          )}
          <Flex flexWrap="wrap" pb={8} flexDirection={["column", "row"]}>
            <Button
              sx={{
                textDecoration: filterType === "all" ? "underline" : "none",
                fontWeight: filterType === "all" ? "bold" : "normal",
              }}
              variant="textLink"
              onClick={() => setFilterType("all")}
            >
              {t("show all")}
            </Button>
            <Button
              sx={{
                textDecoration:
                  filterType === "regulatory" ? "underline" : "none",
                fontWeight: filterType === "regulatory" ? "bold" : "normal",
              }}
              variant="textLink"
              onClick={() => setFilterType("regulatory")}
            >
              {t("Regulatory")}
            </Button>
            <Button
              sx={{
                textDecoration:
                  filterType === "non-regulatory" ? "underline" : "none",
                fontWeight: filterType === "non-regulatory" ? "bold" : "normal",
              }}
              variant="textLink"
              onClick={() => setFilterType("non-regulatory")}
            >
              {t("Non regulatory")}
            </Button>
          </Flex>
          {mfns
            .filter((mfn) => {
              const langs = { swe: 16, eng: 17 };
              if (mfn["mfn-news-tag"].some((v) => [langs[lang]].includes(v)))
                return true;
              return false;
            })
            .filter((mfn) => {
              /* 20 is the ID of mfn-regulatory */
              const pressIDs = [20];
              if (filterType === "regulatory") {
                if (mfn["mfn-news-tag"].some((v) => pressIDs.includes(v)))
                  return true;
                return false;
              }
              if (filterType === "non-regulatory") {
                if (mfn["mfn-news-tag"].some((v) => pressIDs.includes(v)))
                  return false;
                return true;
              }
              return true;
            })
            .slice(0, limit)
            .map(({ slug, title, date_gmt, id, tags }, i, row) => {
              return (
                <Grid key={id} width={[1, 1, 9 / 12]} pb={0}>
                  <Flex
                    py={[0, 0, 6, 6]}
                    sx={{
                      flexDirection: ["column", "column", "row", "row"],
                      borderTop: "2px solid rgba(0,0,0,0.2)",
                      borderBottom:
                        i + 1 === row.length ? "2px solid rgba(0,0,0,0.2)" : "",
                    }}
                  >
                    <Flex
                      width={[1, 1, 6 / 12, 9 / 12]}
                      sx={{ flexDirection: "column" }}
                    >
                      <Box pt={[6, 6, 0, 0]}>
                        <Text
                          variant="h3"
                          mb={3}
                          dangerouslySetInnerHTML={{ __html: title?.rendered }}
                        />
                      </Box>
                      <Flex>
                        <Text fontWeight={"bold"}>
                          {new Date(date_gmt).toLocaleDateString("sv-SE")}
                        </Text>
                        <Box ml={2} sx={{ textTransform: "uppercase" }}>
                          {getTag(tags, t)}
                        </Box>
                      </Flex>
                    </Flex>
                    <Box
                      py={[6, 6, 0, 0]}
                      width={[1, 1, 5 / 12, 3 / 12]}
                      sx={{
                        display: "flex",
                        justifyContent: [
                          "flex-start",
                          "flex-start",
                          "flex-end",
                          "flex-end",
                        ],
                        alignItems: "center",
                      }}
                    >
                      <Button
                        as={Link}
                        target="_blank"
                        to={"/investor-relations/pressmeddelanden/" + slug}
                        variant="primary"
                      >
                        {t("read more")}
                      </Button>
                    </Box>
                  </Flex>
                </Grid>
              );
            })}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Press;
