import { union } from "lodash";

import {
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_PAGINATION_POSTS_REQUEST,
  FETCH_PAGINATION_POSTS_SUCCESS,
  FETCH_SEARCHED_POSTS_REQUEST,
  FETCH_SEARCHED_POSTS_SUCCESS,
  SET_ACTIVE_TAG,
  SET_POST_ACTIVE,
} from "./Post.constant";

export default function posts(
  state = {
    isFetching: false,
    postsByIds: [],
    pagination: {},
    activeTag: null,
    search: [],
    activePost: null,
    postTitle: null,
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_POSTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_POSTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        postsByIds: union(state.postsByIds, action.response.result)
      });
    case FETCH_POSTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    case FETCH_POST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_POST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        postsByIds: union(state.postsByIds, [action.response.result])
      });
    case FETCH_POST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    case FETCH_SEARCHED_POSTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_SEARCHED_POSTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        search: action.response.result
      });
    case FETCH_PAGINATION_POSTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_PAGINATION_POSTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        pagination: {
          ...state.pagination,
          [action.response.page]: action.response.result
        }
      });
    case SET_ACTIVE_TAG:
      return Object.assign({}, state, {
        activeTag: action.response.tag
      });
    case SET_POST_ACTIVE: 
      return Object.assign({}, state, {
        activePost: action.response.post,
        postTitle:  action.response.title
      });
    default:
      return state;
  }
}
