import request from "../../api/request";
import * as types from "./ContactPerson.constant";

function requestContactPerson() {
  return {
    type: types.FETCH_CONTACT_PERSON_REQUEST,

  };
}

function shouldFetchContactPerson(state) {
  return state.contactPerson?.person?.length === 0 && !state.contactPerson.isFetching
}

export function fetchContactPersonIfNeeded() {
  return (dispatch, getState) => {
    return shouldFetchContactPerson(getState())
      ? dispatch(fetchContactPerson())
      : Promise.resolve();
  };
}

function receiveContactPerson(response) {
  return {
    type: types.FETCH_CONTACT_PERSON_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

export function fetchContactPerson() {
  return dispatch => {
    dispatch(requestContactPerson());

    return request(`/theme-settings/contact-person`).then(json =>{
       dispatch(receiveContactPerson(json))
      }
    ).catch((error) => {
      console.log(error)
      dispatch(
      {
        type: types.FETCH_CONTACT_PERSON_FAILURE,
        receivedAt: Date.now()
      });
    });
  }
}
