import React, { useEffect, useState } from "react";
import { Button, Heading } from "rebass";
import { useSelector } from "react-redux";
import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { Text, Box, Flex } from "rebass";
import { useTranslation } from "react-i18next";
import { getTextColor } from "../helpers";

const FinancialCalendar = ({
  financial_calendar_quantity,
  financial_calendar_heading,
  financial_calendar_to_date,
  financial_calendar_from_date,
  background,
}) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredItems, setFilteredItems] = useState([]);
  const { t } = useTranslation();
  const generalFinancialCalendarData = useSelector((state) => {
    return state.financialCalendar;
  });

  const { items, title } = generalFinancialCalendarData;
  const postPerPage = 10;

  useEffect(() => {
    setFilteredItems(items);
    setRows(items);

    if (financial_calendar_from_date || financial_calendar_to_date) {
      const from = Date.parse(financial_calendar_from_date);
      const to = Date.parse(financial_calendar_to_date);
      const today = new Date();

      const itemsFilteredByDate =
        items &&
        items.filter((item) => {
          const postDate = Date.parse(item.financial_calendar_date);
          if (from && to && postDate <= to && postDate >= from) {
            return item;
          }
          if (from && !to && postDate >= from && postDate <= today) {
            return item;
          }
          if (!from && to && postDate <= to) {
            return item;
          }
          return null;
        });

      setFilteredItems(itemsFilteredByDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFinancialCalendarData]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredItems.length / postPerPage));

    if (
      financial_calendar_quantity !== "all" ||
      filteredItems.length <= financial_calendar_quantity
    ) {
      setRows(filteredItems.slice(0, financial_calendar_quantity));
    }

    if (
      financial_calendar_quantity === "all" &&
      filteredItems.length >= postPerPage
    ) {
      setRows(filteredItems.slice(0, postPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItems]);
  if (!generalFinancialCalendarData) {
    return null;
  }

  const handleLoadMore = () => {
    const from = page * postPerPage;
    const to = from + postPerPage;

    setPage(page + 1);
    setRows([...rows, ...filteredItems.slice(from, to)]);
  };

  return (
    <Wrapper
      pt={8}
      pb="60px"
      size="small"
      bg={background}
      color={getTextColor(background)}
    >
      <Container>
        <Flex
          flexWrap="wrap"
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          <Heading
            variant={"h2"}
            mb="32px"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {financial_calendar_heading ? financial_calendar_heading : title}
          </Heading>
          {rows.length > 0 &&
            rows.map((row, i) => {
              return (
                <Row key={i} item={row} isLast={i + 1 === rows.length}></Row>
              );
            })}
          {financial_calendar_quantity === "all" &&
            filteredItems.length > 10 &&
            totalPages > page && (
              <Grid width={[1, 1, 9 / 12]} py={0} mt="5">
                <Button onClick={handleLoadMore}>{t("load more")}</Button>
              </Grid>
            )}
        </Flex>
      </Container>
    </Wrapper>
  );
};

const Row = ({ item, isLast }) => {
  const {
    financial_calendar_title,
    financial_calendar_link,
    financial_calendar_date,
  } = item;

  const d = new Date(financial_calendar_date);
  const date = d ? `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}` : "";

  return (
    <Grid width={[1, 1, 9 / 12]} py={0}>
      <Flex
        py={[6, 6, 0]}
        sx={{
          flexDirection: ["column", "column", "row", "row"],
          borderTop: [
            "2px solid rgba(2,13,29,0.2)",
            "2px solid rgba(2,13,29,0.2)",
            0,
          ],
          borderBottom: [
            isLast ? "2px solid rgba(2,13,29,0.2)" : "",
            isLast ? "2px solid rgba(2,13,29,0.2)" : "",
            0,
          ],
        }}
      >
        <Flex
          mr={5}
          mb={[2, 2, 0]}
          py={[0, 0, 6, 6]}
          width={[1, 1, 5 / 12, 3 / 12, 2 / 12]}
          sx={{
            display: "flex",
            borderTop: [0, 0, "2px solid rgba(2,13,29,0.2)"],
            justifyContent: ["flex-start", "flex-start", "center"],
            borderBottom: [0, 0, isLast ? "2px solid rgba(2,13,29,0.2)" : ""],
          }}
        >
          <Text variant="h3">{date}</Text>
        </Flex>
        <Box
          py={[0, 0, 6, 8]}
          width={[1, 1, 7 / 12, 9 / 12, 10 / 12]}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderTop: [0, 0, "2px solid rgba(2,13,29,0.2)"],
            borderBottom: [0, 0, isLast ? "2px solid rgba(2,13,29,0.2)" : ""],
          }}
        >
          <Text
            fontSize="0"
            fontWeight="bold"
            as={financial_calendar_link ? "a" : "p"}
            target={
              financial_calendar_link ? financial_calendar_link.target : null
            }
            href={financial_calendar_link ? financial_calendar_link.url : null}
            sx={{
              textDecoration: financial_calendar_link ? "underline" : "none",
            }}
          >
            {financial_calendar_title}
          </Text>
        </Box>
      </Flex>
    </Grid>
  );
};

export default FinancialCalendar;
