export const APP_SET_LANG = "APP_SET_LANG";
export const APP_SET_ACF_LANG = "APP_SET_ACF_LANG";
export const APP_SET_LOADING_STATE = "APP_SET_LOADING_STATE";
export const SET_MOBILE_STATE = "SET_MOBILE_STATE";
export const SET_SEARCH_STATE = "SET_SEARCH_STATE";
export const SET_PRIMARY_MENU = "SET_PRIMARY_MENU";
export const FETCH_MENU_REQUEST = "FETCH_MENU_REQUEST";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";

export const BREAKPOINTS = [576, 768, 992, 1200, 1360];
