import React from "react";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";


const YoastHead = ({ json }) => {
    if (!json) return null;

    const options = {
        replace: domNode => {
          if (domNode.name === 'script') {
            return <script type="application/ld+json" class="yoast-schema-graph">{domNode.children[0].data}</script>;
          }
        }
    };

    return (
        <Helmet>
            {parse(json, options)}
        </Helmet>
    );
};

export default YoastHead;