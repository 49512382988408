export const FETCH_POST_REQUEST = "FETCH_POST_REQUEST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_FAILURE = "FETCH_POST_FAILURE";

export const FETCH_POSTS_REQUEST = "FETCH_POSTS_REQUEST";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const FETCH_SEARCHED_POSTS_REQUEST = "FETCH_SEARCHED_POSTS_REQUEST";
export const FETCH_SEARCHED_POSTS_SUCCESS = "FETCH_SEARCHED_POSTS_SUCCESS";

export const FETCH_PAGINATION_POSTS_REQUEST = "FETCH_PAGINATION_POSTS_REQUEST";
export const FETCH_PAGINATION_POSTS_SUCCESS = "FETCH_PAGINATION_POSTS_SUCCESS";
export const SET_ACTIVE_TAG = "SET_ACTIVE_TAG";

export  const SET_POST_ACTIVE = 'SET_POST_ACTIVE'