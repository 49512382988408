import { normalize } from "normalizr";
import fetch from "axios";

/*
 * Utility function to make AJAX respuests using isomorphic fetch.
 * @param Object Data you wish to pass to the server
 * @param String HTTP method, e.g. post, get, put, delete
 * @param String endpoint - defaults to /login
 * @return Promise
 */
const makeRequest = (url) => fetch(url);

export const checkResponseCode = (resp) =>
  resp.status === 200 || resp.status === 201 ? resp : Promise.reject(resp);

export const parseJSONWithSchema = (schema, single) => (resp) => {
  const json = single ? resp.data[0] : resp.data;
  return !schema ? json : normalize(json, schema);
};

export const request = (endpoint, schema, single) => {
  const url = process.env.REACT_APP_API_URL;
  return makeRequest(url + endpoint)
    .then(checkResponseCode)
    .then(parseJSONWithSchema(schema, single));
};

export default request;
