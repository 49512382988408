import { injectGlobal } from "styled-components";

injectGlobal`

  * {
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  html,
  body,
  #root {
    font-family: Montserrat;
    background-color: #E7E9EF;
    font-size: 13px;
    margin: 0;
    padding: 0;
    color: black;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  a {
		color: black;
    text-decoration: none;
	}

  .post-view a {
		color: #0076c0;
    text-decoration: underline;
	}

  .slick-initialized, .slick-list, .slick-track {
    height: 100%;
  }

  p {
    padding: 0;
    margin: 0;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.6; /* Firefox */
  }

  button:focus {outline:0;}

`;
