import {
  FETCH_DISCLAIMER_REQUEST,
  FETCH_DISCLAIMER_SUCCESS,
  FETCH_DISCLAIMER_FAILURE,
} from "./Disclaimer.constant";

export default function disclaimer(
  state = {
    isFetching: false,
    data: [],
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_DISCLAIMER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_DISCLAIMER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.response.data
      });
    case FETCH_DISCLAIMER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
