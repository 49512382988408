import Schemas from "../../api/schemas";
import request from "../../api/request";
import * as types from "./Tag.constant";

function requestTags() {
  return {
    type: types.FETCH_TAGS_REQUEST
  };
}

function receiveTags(response) {
  return {
    type: types.FETCH_TAGS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchTags(f) {
  return dispatch => {
    dispatch(requestTags());
    const filter = f
      ? `?${f}`
      : "?fields=id,date,slug,status,type,title,link,menu_order";
    return request(`/tags/${filter}`, Schemas.TAG_ARRAY).then(json => {
      return dispatch(receiveTags(json));
    });
  };
}

export function fetchTagsIfNeeded(filter) {
  return (dispatch, getState) => {
    if (!getState().tag.tagsByIds.length) {
      return dispatch(fetchTags(filter));
    }
  };
}
