import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Flex, Image, Link as RLink, Text } from "rebass";

import Container from "../ui/Container";
import Grid from "../ui/Grid";

import addimotion from "../assets/Addimotion.png";
import tiqqe from "../assets/TIQQE.png";
import zendesk from "../assets/Zendesk.png";

import { useLocation } from 'react-router-dom';
import facebook from "../assets/Facebook.png";
import linkedin from "../assets/Linkedin.png";
import medium from "../assets/Medium.png";
import FloatButton from "../ui/parts/FloatButton";

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const pages = useSelector((state) => {
    return Object.values(state.entities.pages);
  });
  const lang = useSelector((state) => state.app.lang);
  const primaryMenu = useSelector((state) => state.app.menus.primary);
  if (!pages) return null;

  return (
    <Box pt={8} pb={13} bg="#020E1E" color="white">
      <Container>
        <Flex flexWrap="wrap">
          <Grid width={[1, 1 / 4]}>
            <Text pb={3} variant="paragraph">
              Sonat:
            </Text>
            {primaryMenu &&
              primaryMenu.map((page) => (
                <Link to={page.url} key={page.url}>
                  <Text
                    pb={2}
                    fontWeight="bold"
                    variant="paragraph"
                    color="white"
                  >
                    {page.title[lang]}
                  </Text>
                </Link>
              ))}
          </Grid>

          <Grid width={[1, 1 / 4]}>
            <Text pb={3} variant="paragraph">
              {t("contact")}:
            </Text>
            <Text pb={2} variant="paragraph">
              Sonat Stockholm <br />
              Klarabergsviadukten 63 <br />
              111 64 Stockholm <br />
              Tel:{" "}
              <RLink variant="negLink" href="phone:+46 8 20 27 40">
                +46 8 20 27 40
              </RLink>{" "}
              <br />
              {t("e-mail")}:{" "}
              <RLink variant="negLink" href="mailto:info@sonat.se">
                info@sonat.se
              </RLink>
            </Text>
            <Text pb={2} variant="paragraph">
              Sonat Örebro <br />
              Fabriksgatan 24, 4 tr <br />
              702 23 Örebro <br />
              Tel:{" "}
              <RLink variant="negLink" href="phone:+46 8 20 27 40">
                +46 8 20 27 40
              </RLink>{" "}
              <br />
              {t("e-mail")}:{" "}
              <RLink variant="negLink" href="mailto:info@sonat.se">
                info@sonat.se
              </RLink>
            </Text>
          </Grid>

          <Grid width={[1, 1 / 4]}>
            <Text pb={3} variant="paragraph">
              {t("partners")}:
            </Text>
            <Box pb={4}>
              <Image src={tiqqe} maxHeight="17px" display="block" mb={3} />
              <Image src={addimotion} maxHeight="15px" display="block" mb={3} />
              <Image src={zendesk} maxHeight="17px" display="block" mb={2} />
            </Box>
          </Grid>

          <Grid width={[1, 1 / 4]}>
            <Box pb={6}>
              <Text pb={3} variant="paragraph">
                {t("legal")}:
              </Text>
              <Text pb={2} variant="paragraph" fontWeight="bold">
                <RLink as={Link} variant="neg" to="/cookies">
                  {t("cookies")}
                </RLink>
              </Text>
              <Text pb={2} variant="paragraph" fontWeight="bold">
                Copyright Sonat ab
              </Text>
            </Box>
            <Text pb={2} variant="paragraph">
              {t("social")}:
            </Text>
            <Box pb={6}>
              <a
                href="https://www.linkedin.com/company/sonat-ab/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image src={linkedin} maxHeight="18px" mr={2} />
              </a>
              <a
                href="https://www.facebook.com/sonat4pl/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image src={facebook} maxHeight="18px" mr={2} />
              </a>
              <a
                href="https://medium.com/@SonatAB"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image src={medium} maxHeight="18px" mr={2} />
              </a>
            </Box>
          </Grid>
        </Flex>
      </Container>
      {(location.pathname === "/" || location.pathname === "/home/") &&
        <FloatButton/>
      }
    </Box>
  );
};

export default Footer;
