import request from "../../api/request";
import * as types from "./FinancialCalendar.constant";

function requestFinancialCalendar() {
  return {
    type: types.FETCH_FINANCIAL_CALENDAR_REQUEST,
  };
}

function shouldFetchFinancialCalendar(state) {
  return true;
  //return state.financialCalendar.items.length === 0 && !state.financialCalendar.isFetching;
}

export function fetchFinancialCalendarIfNeeded() {
  return (dispatch, getState) => {
    return shouldFetchFinancialCalendar(getState())
      ? dispatch(fetchFinancialCalendar())
      : Promise.resolve();
  };
}

function receiveFinancialCalendar(response) {
  return {
    type: types.FETCH_FINANCIAL_CALENDAR_SUCCESS,
    response,
    receivedAt: Date.now(),
  };
}

export function fetchFinancialCalendar() {
  return (dispatch) => {
    dispatch(requestFinancialCalendar());

    return request(`/theme-settings/financial-calendar`)
      .then((json) => {
        dispatch(receiveFinancialCalendar(json));
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: types.FETCH_FINANCIAL_CALENDAR_FAILURE,
          receivedAt: Date.now(),
        });
      });
  };
}
