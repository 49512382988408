import React from "react";

const PdfIcon = ({ color }) => {
    return (
        <svg 
        color={color}
        width="22"
        height="26.5"
        viewBox="0 0 463.8 560"
        >
            <path fill="currentColor" d="M155 171.8c0-9.7-6.7-15.5-18.6-15.5-4.8 0-8.1.5-9.8.9v31.1c2 .6 4.5.7 7.9.7 12.7 0 20.5-6.4 20.5-17.2z"/>
            <path fill="currentColor" d="M64.6 0C28.9 0 0 28.9 0 64.6V560h399.2c35.7 0 64.6-28.9 64.6-64.6V0H64.6zm234.8 139.2h64.3v19.5h-40.4v24.1h37.8v19.4h-37.8v42.3h-23.9V139.2zm-107.2 1.4c8.7-1.4 20.1-2.2 32.2-2.2 20 0 33 3.6 43.1 11.3 10.9 8.1 17.8 21.1 17.8 39.7 0 20.2-7.3 34.1-17.5 42.6-11.1 9.2-28 13.6-48.6 13.6-12.3 0-21.1-.8-27-1.6V140.6zm-89.2 0c7.3-1.2 17.6-2.2 32.2-2.2 14.7 0 25.1 2.8 32.2 8.4 6.7 5.3 11.3 14.1 11.3 24.4 0 10.3-3.4 19.1-9.7 25-8.1 7.7-20.1 11.1-34.2 11.1-3.1 0-5.9-.2-8.1-.5v37.6H103V140.6zm282.7 333.9H76.9v-35h308.8v35zm0-51.6H76.9v-35h308.8v35zm0-51.7H76.9v-35h308.8v35z"/>
            <path fill="currentColor" d="M260 190.1c.1-21.9-12.7-33.4-33.1-33.4-5.3 0-8.8.5-10.8.9v68.9c2 .5 5.3.5 8.3.5 21.5.1 35.6-11.7 35.6-36.9z"/>
        </svg>
    )
  }
export default PdfIcon;