import React from "react";
import parse from "html-react-parser"
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { Box } from "rebass";
import ContentPost from "../ui/ContentPost";

const TextImage = ({
  text_image_content
})=>{
  if(!text_image_content) return null
  return(
      <Container>
        <Grid>
          <Box pb={6} mt={9}>
            <ContentPost half>
              {parse(text_image_content)}
            </ContentPost>
          </Box>
        </Grid>
      </Container>
  )
}

export default TextImage;