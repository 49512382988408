import Schemas from "../../api/schemas";
import request from "../../api/request";
import * as types from "./Mfn.constant";

function requestMfn(slug) {
  return {
    type: types.FETCH_MFN_REQUEST,
    slug
  };
}

function receiveMfn(response) {
  return {
    type: types.FETCH_MFN_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchMfn(slug) {
  return dispatch => {
    dispatch(requestMfn(slug));
    return request(`/mfn_news/?slug=${slug}`, Schemas.MFN, true).then(json => {
      return dispatch(receiveMfn(json));
    });
  };
}

function shouldFetchMfn(state, slug) {
  const post = state.entities.posts[slug];
  return !(post && post.content && post.acf);
}

export function fetchMfnIfNeeded(slug) {
  return (dispatch, getState) => {
    return shouldFetchMfn(getState(), slug)
      ? dispatch(fetchMfn(slug))
      : Promise.resolve();
  };
}

/*

*/

function requestMfns() {
  return {
    type: types.FETCH_MFNS_REQUEST
  };
}

function receiveMfns(response) {
  return {
    type: types.FETCH_MFNS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchMfns(f, { tags, ids, slugs }) {
  return dispatch => {
    dispatch(requestMfns());
    const t = tags ? `tags=${tags}&` : "";
    const i = ids ? `includes=${ids}&` : "";
    const s = slugs ? `slug=${slugs}&` : "";
    const filter = f
      ? `?${t}${i}${s}${f}`
      : `?${i}${i}fields=id,date,slug,status,type,title,link,menu_order,tags,acf`;
    return request(`/mfn_news/${filter}`, Schemas.MFN_ARRAY).then(json => {
      return dispatch(receiveMfns(json));
    });
  };
}

export function fetchMfnsIfNeeded(filter, options) {
  return (dispatch, getState) => {
    if (!getState().post.postsByIds.length || options) {
      return dispatch(fetchMfns(filter, options));
    }
  };
}

/*

*/

function requestSearchedMfns() {
  return {
    type: types.FETCH_SEARCHED_MFNS_REQUEST
  };
}

function receiveSearchedMfns(response) {
  return {
    type: types.FETCH_SEARCHED_MFNS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

export function searchMfns(query) {
  return dispatch => {
    dispatch(requestSearchedMfns());

    return request(
      `/mfn_news/?per_page=30&order=asc&search=${query}`,
      Schemas.MFN_ARRAY
    ).then(json => {
      return dispatch(receiveSearchedMfns(json));
    });
  };
}

/*

*/

function requestPaginationMfns() {
  return {
    type: types.FETCH_PAGINATION_MFNS_REQUEST
  };
}

function receivePaginationMfns(response) {
  return {
    type: types.FETCH_PAGINATION_MFNS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchPaginationMfns(f, page = 1) {
  return dispatch => {
    dispatch(requestPaginationMfns());
    const p = `per_page=100&page=${page}&`;
    const filter = f
      ? `?${p}${f}`
      : `?${p}fields=id,date,slug,status,type,title`;
    return request(`/mfn_news/${filter}`, Schemas.MFN_ARRAY).then(json => {
      return dispatch(receivePaginationMfns({ ...json, page }));
    });
  };
}

export function fetchPaginationMfnsIfNeeded(filter, page) {
    return (dispatch, getState) => {
      if (!getState().mfn.pagination[page]) {
      return dispatch(fetchPaginationMfns(filter, page));
    }
  };
}

export function setActiveTag(tag) {
  return dispatch => {
    return dispatch({
      type: types.SET_ACTIVE_TAG,
      response: { tag }
    });
  };
}
