import React, { useState } from 'react'
import Select from 'react-select'
import parse from "html-react-parser"

import { Box, Text, Button, Flex } from "rebass";
import { Helmet } from "react-helmet";
import Container from "../../ui/Container";
import { useHistory, Link } from "react-router-dom";
import Grid from "../../ui/Grid";
import { useSelector, useDispatch } from "react-redux";
import { fetchDisclaimerIfNeeded } from "../../containers/Disclaimer/Disclaimer.action";
import ContentPost from "../../ui/ContentPost";

const Disclaimer = () => {
    const dispatch = useDispatch();
    dispatch(fetchDisclaimerIfNeeded());
    const lang = useSelector((state) => state.app.lang);
    const [disclaimerPage, setDisclaimerPage] = useState(0);
    const postTitle = {'eng': "Disclaimer", 'swe': "Disclaimer"};
    const deniedTitle = {'eng': "Disclaimer Denied", 'swe': "Disclaimer Denied"};
    
    const disclaimerData = useSelector((state) => {
        return state.disclaimer;
    });

    const contentUpper = disclaimerData?.data?.upper;
    const contentLower = disclaimerData?.data?.lower;
    const contentPage2 = disclaimerData?.data?.second;
    const deniedContent = disclaimerData?.data?.denied;

    const history = useHistory();
    const approvedHandler = () => {
        /* Set local storage */
        localStorage.setItem('Sonat-IR-disclaimer', new Date());
        /* Redirect to */
        history.push("/investor-relations/borsnotering/vd-ord");
    }

    const goToNextPage = () => {
        window.scrollTo(0, 0);
        setDisclaimerPage(1);
    }
    const denyHandler = () => {
        window.scrollTo(0, 0);
        setDisclaimerPage(2);
    }


    const options = [
        { value: 'sweden', label: 'Sverige' },
        { value: 'dk', label: 'Danmark' },
        { value: 'no', label: 'Norge' }
    ];

    const customSelectStyles = {
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            background: isFocused
                ? 'rgba(7, 54, 146, 0.5)'
                : isSelected
                    ? 'rgba(7, 54, 146, 1)'
                    : undefined,
            zIndex: 1,
            cursor: 'pointer',
        }),

        control: (styles) => ({
            ...styles,
            cursor: 'pointer',
            maxWidth: '200px',
        }),

        menu: (base) => ({
            ...base,
            maxWidth: '200px',
       }),
    };
      
    const CountrySelect = () => (
        <Select options={options} styles={customSelectStyles} defaultValue={ { value: 'sweden', label: 'Sverige' }}/>
    );

    return (
        <div className={"disclaimer-view"}>
            <Helmet>
                <title>{postTitle[lang]}</title>
            </Helmet>
            {disclaimerPage === 0 && (
                <Container>
                    <Grid>
                        <Text variant="h2" pt={10} pb={6}>
                            {postTitle[lang]}
                        </Text>
                        {contentUpper && (
                            <ContentPost half>
                                {parse(contentUpper)}
                            </ContentPost>
                        )}
                        <Box py={6} width={[1, 1, 6 / 12]}>
                            <CountrySelect/>
                        </Box>
                        {contentLower && (
                            <ContentPost half>
                                {parse(contentLower)}
                            </ContentPost>
                        )}
                        <Button
                            mt={6}
                            onClick={() => goToNextPage()}
                            variant="primary">
                            {"Ok"}
                        </Button>
                    </Grid>
                </Container>
            )}
            {disclaimerPage === 1 && (
                <Container>
                    <Grid>
                        <Text variant="h2" pt={10} pb={6}>
                            {postTitle[lang]}
                        </Text>
                        {contentPage2 && (
                            <ContentPost pb={6} half>
                                {parse(contentPage2)}
                            </ContentPost>
                        )}
                        <Flex flexWrap="wrap" pt={5} sx={{flexDirection: ["column", "column", "row"], alignItems: "center"}}>
                            <Button 
                                onClick={() => approvedHandler()}
                                variant="primary"
                                mr={[0,0,5]}>
                                {"Ok"}
                            </Button>
                            <Button 
                                onClick={() => denyHandler()}
                                variant="primary">
                                {"Refuse"}
                            </Button>
                        </Flex>
                    </Grid>
                </Container>
            )}
            {disclaimerPage === 2 && (
                <Container>
                    <Grid>
                        <Text variant="h2" pt={10} pb={6}>
                            {deniedTitle[lang]}
                        </Text>
                        {deniedContent && (
                            <ContentPost pb={6} half>
                                {parse(deniedContent)}
                            </ContentPost>
                        )}
                        <Flex flexWrap="wrap" pt={5} sx={{flexDirection: ["column", "column", "row"], alignItems: "center"}}>
                            <Button
                                as={Link} 
                                to={'/'}
                                variant="primary">
                                {"Gå till startsidan"}
                            </Button>
                        </Flex>
                    </Grid>
                </Container>
            )}
        </div>
    );
};

export default Disclaimer;
