import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Text, Flex, Box } from "rebass";
import parse from "html-react-parser"
import { useTranslation } from "react-i18next";
import { getTag } from "../../utils";

import { Helmet } from "react-helmet";

import Container from "../../ui/Container";
import Grid from "../../ui/Grid";
import ContentPost from "../../ui/ContentPost";

import { fetchMfnIfNeeded } from "./Mfn.action";
import Divider from "../../components/Divider";
import { fetchContactPersonIfNeeded } from "../ContactPerson/ContactPerson.action";
import YoastHead from "../../components/YoastHead";

const Mfn = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const path = params.path;
  const isFetching = useSelector((state) => state.mfn.isFetching);
  const mfn = useSelector((state) => state.entities.mfns[path]);
  const { t } = useTranslation();
  const mfnTitle = mfn ? mfn.title.rendered : 'Sonat';

  const contactPerson = useSelector((state) => {
      return state.contactPerson;
  });

  useEffect(() => {
    dispatch(fetchMfnIfNeeded(path));
    dispatch(fetchContactPersonIfNeeded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  if (isFetching || !mfn) return <div>loading</div>;

  const { content, attachments, date_gmt, tags, yoast_head } = mfn;
  return (
    <div className={"mfn-view"}>
      <Helmet>
        <title>{ mfnTitle }</title>
      </Helmet>
      <YoastHead json={ yoast_head } />
      <Container pb={6} mt={9}>
        <Grid>

        <Flex>
            <Text fontWeight={"bold"}>
                {new Date(date_gmt).toLocaleDateString("sv-SE")}
            </Text>
            <Box ml={2} sx={{ textTransform: "uppercase" }}>
                {getTag(tags, t)}
            </Box>
        </Flex>
          <Text pb={6} variant="h2">
            {mfnTitle}
          </Text>
          {content.rendered  && (
            <ContentPost half>
              {parse(removeAttatchments(content.rendered))}
            </ContentPost>
          )}
        </Grid>
      </Container>
        {attachments && attachments.map((pdf, i) => {
            return (
                <Divider key={i} background={i%2 ? "#545D6A" : "#D0D6DE" } type={[{acf_fc_layout: "cta", text: pdf.file_title, button_text: pdf.file_title, button_link: {title: pdf.file_title, url: pdf.url}}]}></Divider>
                )
            })}
        <Divider background={"#073692"} type={getContactPerson(contactPerson)}></Divider>
    </div>
  );
};


function getContactPerson(contactPerson){
    const {person} = contactPerson;
    return [{acf_fc_layout: "person", person: person}];
}

function removeAttatchments(str) {
    const attachmentsIndex = str.search('<div class="mfn-attachments-container">')
    if (attachmentsIndex < 1) {
        return str;
    }
    return str.slice(0, attachmentsIndex);
}

export default Mfn;
