import {
  APP_SET_LANG,
  APP_SET_ACF_LANG,
  APP_SET_LOADING_STATE,
  SET_MOBILE_STATE,
  SET_SEARCH_STATE,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_REQUEST,
} from "./App.constant";

export default function app(
  state = {
    isLoading: false,
    lang: "eng",
    acfLang: "eng",
    mobileState: false,
    searchActive: false,
    menus: {},
  },
  action = {}
) {
  switch (action.type) {
    case APP_SET_LANG:
      return Object.assign({}, state, {
        lang: action.response.lang,
      });
    case APP_SET_ACF_LANG:
      return Object.assign({}, state, {
        acfLang: action.response.acfLang,
      });
    case APP_SET_LOADING_STATE:
      return Object.assign({}, state, {
        isLoading: action.response.isLoading,
      });
    case SET_MOBILE_STATE:
      return Object.assign({}, state, {
        mobileState: action.response.active,
      });
    case SET_SEARCH_STATE:
      return Object.assign({}, state, {
        searchActive: action.response.active,
      });
    case FETCH_MENU_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_MENU_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        menus: { ...state.menus, ...action.response },
      });
    default:
      return state;
  }
}
