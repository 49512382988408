import styled from "styled-components";

const ContentPost = styled.div.attrs((props) => props)`
font-size: 16px;
line-height: 1.5;
margin-bottom: ${props=> props.mb ? `${props.mb}px`: '0'};

h1, h2, h3, h4, h5, h6 {
  max-width: 100%;
  margin-bottom: 0;
  line-height: 1.2;

  @media (min-width: 768px) {
    max-width: ${props=> props.half ? '50%': '100%'};
  }
}

h1 {
  font-size: 27px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

h2 {
  font-size: 27px;
  font-weight: 500;
  margin-top: 60px;
}

p, table {
  margin-top: ${props=> props.m ? `${props.m}px`: '30px'};
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: ${props=> props.half ? '50%': '100%'};
  }
}

p:first-child, table:first-child {
    margin-top: 0;
}

hr {
  max-width: 100%;
  margin-left: 0;

  @media (min-width: 768px) {
    max-width: ${props=> props.half ? '50%': '100%'};
  }
}

.preamble {
  font-weight: 700;
}

a {
  font-weight: bold;
  text-decoration: underline;
}

ol, ul {
  margin-top: ${props=> props.m ? `${props.m}px`: '30px'};

  list-style-position: inside;
  padding: 0;
  list-style: none;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: ${props=> props.half ? '50%': '100%'};
  }

  ol, ul {
    margin-top: 20px;

    @media (min-width: 768px) {
        max-width: 100%;
    }
  }
}

ol {
  counter-reset: custom-ol-counter;

  li {
    counter-increment: custom-ol-counter;
    position: relative;
    margin-left: 20px;
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: counter(custom-ol-counter) ".";
      position: absolute;
      top: 0;
      left: -20px;
    }
  }
}

ul {
  li {
    margin-left: 20px;
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      display: block;
      top: 7px;
      left: -20px;
      background-color: #0B51E0;
      border-radius: 99px;
    }
  }
}

img {
  width: 100%;
  height: auto;
  margin-top: ${props=> props.m ? `${props.m}px`: '30px'};

  @media (min-width: 768px) {
    width: ${props=> props.half ? '58.33%': '100%'};

  }
}

.youtube-wrapper {
  margin-top: ${props=> props.m ? `${props.m}px`: '30px'};
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  @media (min-width: 768px) {
    width: ${props=> props.half ? '58.33%': '100%'};
    padding-bottom: ${props=> props.half ? '32.81%': ' 56.25%'}; /* 16:9 */

  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.mfn-footer {
    margin-top: 20px;
}

.mfn-preamble {
    margin-bottom: 20px;
}

`;

export default ContentPost;