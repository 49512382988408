import React from "react";
import styled from "styled-components";

const Burger = styled("div")`
  width: 20px;
  height: 2px;
  background-color: black;
  margin: 3px 0;
  &:hover {
    cursor: pointer;
  }
`;

export default () => (
  <React.Fragment>
    <Burger />
    <Burger />
    <Burger />
  </React.Fragment>
);
