import React from "react";
import { Link } from "react-router-dom";
import { Button, Flex } from "rebass";
import PdfIcon from "../../assets/icons/pdf"

const PdfButton = ({ link }) => {
  return (
    <Button 
      as={Link}
      target="_blank"
      sx={{
        paddingLeft: "60px",
        paddingRight: "20px",
        position: "relative",
      }}
      to={{pathname:`${link['url']}`}}
      variant="primary">
        <Flex 
          sx={{
            backgroundColor: "#924D16",
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            width: "40px",
            color: 'white',
            alignItems: "center",
            justifyContent: "center"
          }}
        >
            <PdfIcon color="white"></PdfIcon>
        </Flex>
        {link['title']}
    </Button>
  );
};

export default PdfButton;
