import React from "react";
import { Box } from "rebass";

const top = {
  xlarge: [6, 10],
  large: [6, 10],
  small: [6, 6],
  xsmall: [6, 6],
};

const bottom = {
  xlarge: [10],
  large: [0, 0, 4],
  small: [0, 0, 4],
  xsmall: [0, 0],
};

export default ({ size = "small", ...rest }) => (
  <Box pt={top[size]} pb={bottom[size]} {...rest} />
);
