import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Text, Box, Flex, Image } from "rebass";
import styled, { css } from "styled-components";

import { BREAKPOINTS } from "../containers/App/App.constant";
import {
  toggleMenu,
  setLang,
  setACFLang,
  toggleSearch,
} from "../containers/App/App.action";

import Container from "../ui/Container";
import Hamburger from "../ui/Hamburger";

import { useWindowSize } from "../utils";

import logo from "../assets/sonat_logo.svg";
import search from "../assets/Magnifying_glass.svg";

import InvestorRelationsMenuDesktop from "./Menus/InvestorRelationsDesktop";
import InvestorRelationsMenuMobile from "./Menus/InvestorRelationsMobile";

const Items = styled(Box)``;

export const active = css`
  &::after {
    content: "";
    background: black;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const CustomLink = styled(Box)`
  ${(props) => props?.active && active};
  position: relative;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

const Dropdown = styled(Container)`
  display: ${(props) => (props?.active ? "flex" : "none")};
  overflow-y: auto;
  height: calc(100vh - 61px) !important;
`;

const Mobile = ({
  primaryMenu,
  route,
  lang,
  onLangPress,
  onSeatchPress,
  irMenu,
}) => {
  const dispatch = useDispatch();
  const active = useSelector((state) => state.app.mobileState);

  return (
    <Box
      bg={["#E7E9EF"]}
      sx={{
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        zIndex: 9,
        borderBottom: "1px solid black",
      }}
    >
      <Container px={2} py={3}>
        <Flex
          sx={{
            alignItems: "center",
          }}
        >
          <Box mr={4} onClick={() => dispatch(toggleMenu(!active))}>
            <Hamburger />
          </Box>
          <Box>
            <NavLink to="/">
              <Image src={logo} height="16px" />
            </NavLink>
          </Box>
          <Box mx="auto" />
          <Box>
            <Flex
              sx={{
                alignItems: "center",
              }}
            >
              <Box>
                <Text variant="nav">
                  <CustomLink
                    py={1}
                    active={lang === "eng"}
                    onClick={() => onLangPress("eng")}
                  >
                    Eng
                  </CustomLink>
                </Text>
              </Box>
              <Box mx={1}>
                <Text variant="nav">/</Text>
              </Box>
              <Box mr={4}>
                <Text variant="nav">
                  <CustomLink
                    py={1}
                    active={lang === "swe"}
                    onClick={() => onLangPress("swe")}
                  >
                    Swe
                  </CustomLink>
                </Text>
              </Box>
              <Box onClick={onSeatchPress}>
                <Box>
                  <Image src={search} height="16px" />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
      <Dropdown active={active}>
        <Box px={2} pl="18px" pt={1} pb={4}>
          <Flex flexDirection="column" alignItems="flex-start">
            {primaryMenu &&
              primaryMenu.map((page, i) => {
                return (
                  <CustomLink
                    as={NavLink}
                    to={page.url || "/"}
                    active={
                      route === page.url ||
                      (page.url === "/home/" && route === "/")
                    }
                    key={i}
                    py={1}
                    my={1}
                  >
                    {page.title[lang]}
                  </CustomLink>
                );
              })}
          </Flex>
        </Box>
        {irMenu && (
          <InvestorRelationsMenuMobile
            route={route}
            irMenu={irMenu}
            lang={lang}
          />
        )}
      </Dropdown>
    </Box>
  );
};

const Desktop = ({
  route,
  lang,
  onLangPress,
  onSeatchPress,
  primaryMenu,
  onIrOver,
  onIrOut,
}) => (
  <Box
    bg={["#E7E9EF"]}
    sx={{
      position: "fixed",
      top: "0px",
      left: "0px",
      right: "0px",
      zIndex: 9,
      borderBottom: "1px solid black",
    }}
  >
    <Container px={2}>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        <Items>
          <NavLink to="/">
            <Image src={logo} height="20px" />
          </NavLink>
        </Items>
        <Box mx="auto" />
        <Items>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {primaryMenu &&
              primaryMenu.map((page, y) => {
                return (
                  <Text variant="nav" key={y}>
                    <NavLink as={NavLink} to={page.url || "/"} key={page.url}>
                      <CustomLink
                        mx={3}
                        py={3}
                        active={
                          route === page.url ||
                          (page.url === "/home/" && route === "/")
                        }
                        onMouseEnter={() =>
                          page.event === "ir" ? onIrOver() : onIrOut()
                        }
                      >
                        {page.title[lang]}
                      </CustomLink>
                    </NavLink>
                  </Text>
                );
              })}
          </Flex>
        </Items>

        <Box mx="auto" />
        <Items>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Text variant="nav">
                <CustomLink
                  py={3}
                  active={lang === "eng"}
                  onClick={() => onLangPress("eng")}
                  onMouseEnter={() => onIrOut()}
                >
                  Eng
                </CustomLink>
              </Text>
            </Box>
            <Box mx={1}>
              <Text variant="nav">/</Text>
            </Box>
            <Box>
              <Text variant="nav">
                <CustomLink
                  py={3}
                  active={lang === "swe"}
                  onClick={() => onLangPress("swe")}
                  onMouseEnter={() => onIrOut()}
                >
                  Swe
                </CustomLink>
              </Text>
            </Box>
            <Box ml={4}>
              <Box sx={{ cursor: "pointer" }} onClick={onSeatchPress}>
                <Image src={search} height="20px" />
              </Box>
            </Box>
          </Flex>
        </Items>
      </Flex>
    </Container>
  </Box>
);

const Header = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => Object.values(state.entities.pages));
  const size = useWindowSize();
  const [isShown, setIsShown] = useState(false);
  const lang = useSelector((state) => state.app.lang);
  const acfLang = useSelector((state) => state.app.acfLang);
  const route = useSelector((state) => state.router.location.pathname);
  const searchActive = useSelector((state) => state.app.searchActive);
  const irMenu = useSelector((state) => state.app.menus.ir);
  const primaryMenu = useSelector((state) => state.app.menus.primary);
  const location = useLocation();
  const activeACF = useSelector((state) => state?.page?.activePage?.acf);
  
  const languageHandler = (lang) => {
    dispatch(setLang(lang));
    if (activeACF && !activeACF[lang]) {
        dispatch(setACFLang(lang === "swe" ? "eng" : "swe"))
    } else {
        dispatch(setACFLang(lang))
    }
  }

  useEffect(() => {
    if (activeACF && !activeACF[lang] && acfLang === lang) {
        dispatch(setACFLang(lang === "swe" ? "eng" : "swe"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeACF]);
  
  

  useEffect(() => {
    setIsShown(false);
  }, [location]);

  if (!pages) return null;
  return (
    <>
      {size.width < BREAKPOINTS[2] ? (
        <Mobile
          primaryMenu={primaryMenu}
          lang={lang}
          route={route}
          onLangPress={(lang) => {
            dispatch(toggleMenu(false));
            languageHandler(lang)
          }}
          onSeatchPress={() => dispatch(toggleSearch(!searchActive))}
          irMenu={irMenu}
        />
      ) : (
        <>
          <Desktop
            lang={lang}
            route={route}
            onLangPress={(lang) => languageHandler(lang)}
            onSeatchPress={() => dispatch(toggleSearch(!searchActive))}
            primaryMenu={primaryMenu}
            onIrOver={() => setIsShown(true)}
            onIrOut={() => setIsShown(false)}
          />
          {irMenu && isShown && (
            <InvestorRelationsMenuDesktop
              route={route}
              irMenu={irMenu}
              onMenuEnter={() => setIsShown(true)}
              onMenuOut={() => setIsShown(false)}
              lang={lang}
            />
          )}
        </>
      )}
    </>
  );
};

export default Header;
