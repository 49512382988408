import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const langs = {
  eng: {
    translation: {
      "read more": "Read more",
      "show all": "Show all",
      contact: "Contact",
      "e-mail": "E-mail",
      partners: "Partners",
      supporting: "Supporting",
      legal: "Legal",
      social: "Social",
      view: "View",
      search: "Search",
      close: "Close",
      cookies: "Cookies",
      'Regulatory': "Regulatory",
      'Non regulatory': "Non regulatory",
      "load more": "Load more"
    }
  },
  swe: {
    translation: {
      "read more": "Läs mer",
      "show all": "Visa alla",
      contact: "Kontakt",
      "e-mail": "E-post",
      partners: "Partners",
      supporting: "Supporting",
      legal: "Legal",
      social: "Social",
      view: "Läs mer",
      search: "Sök",
      close: "Stäng",
      cookies: "Cookies",
      'Regulatory': "Regulatoriskt",
      'Non regulatory': "Icke-regulatoriskt",
      "load more": "Ladda fler"
    }
  }
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "eng",
    debug: true,
    resources: langs,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default,
    }
  });

export default i18n;
