import { union } from "lodash";

import {
  FETCH_PEOPLE_REQUEST,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE
} from "./Person.constant";

export default function tags(
  state = {
    isFetching: false,
    peopleByIds: []
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_PEOPLE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_PEOPLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        peopleByIds: union(state.tagsByIds, action.response.result)
      });
    case FETCH_PEOPLE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
