import { union } from "lodash";

import {
  FETCH_MFN_REQUEST,
  FETCH_MFN_SUCCESS,
  FETCH_MFN_FAILURE,
  FETCH_MFNS_REQUEST,
  FETCH_MFNS_SUCCESS,
  FETCH_MFNS_FAILURE,
  FETCH_PAGINATION_MFNS_REQUEST,
  FETCH_PAGINATION_MFNS_SUCCESS,
  FETCH_SEARCHED_MFNS_REQUEST,
  FETCH_SEARCHED_MFNS_SUCCESS,
  SET_ACTIVE_TAG,
  SET_MFN_ACTIVE,
} from "./Mfn.constant";

export default function mfns(
  state = {
    isFetching: false,
    mfnsByIds: [],
    pagination: {},
    activeTag: null,
    search: [],
    activeMfn: null,
    postTitle: null,
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_MFNS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_MFNS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        mfnsByIds: union(state.mfnsByIds, action.response.result)
      });
    case FETCH_MFNS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    case FETCH_MFN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_MFN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        mfnsByIds: union(state.mfnsByIds, [action.response.result])
      });
    case FETCH_MFN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    case FETCH_SEARCHED_MFNS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_SEARCHED_MFNS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        search: action.response.result
      });
    case FETCH_PAGINATION_MFNS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_PAGINATION_MFNS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        pagination: {
          ...state.pagination,
          [action.response.page]: action.response.result
        }
      });
    case SET_ACTIVE_TAG:
      return Object.assign({}, state, {
        activeTag: action.response.tag
      });
    case SET_MFN_ACTIVE: 
      return Object.assign({}, state, {
        activeMfn: action.response.post,
        postTitle:  action.response.title
      });
    default:
      return state;
  }
}
