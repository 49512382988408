import "./ui/global";
import "./config/i18n";

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";

import theme from "./ui/theme";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./config/configureStore.js";

const store = configureStore();
const root = document.getElementById("root");

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
