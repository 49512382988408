let darkBackgroundColors = [
  "#020d1d",
  "#2b3544",
  "#545d6a",
  "#0b51e0",
  "#073692",
  "#924d16",
];

export function getTextColor(background) {
  if (background && darkBackgroundColors.includes(background.toLowerCase())) {
    return "white";
  } else {
    return "#020d1d";
  }
}
