import React, { useEffect, useRef } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

function HtmlParserAndExecutor({ html, cbConsent, setCbConsent }) {
    const containerRef = useRef(null);  // Create a ref for the container

    useEffect(() => {
        if (cbConsent) {
            return;
        }

        // Extract scripts manually from the HTML string
        const doc = new DOMParser().parseFromString(html, "text/html");
        const scripts = doc.getElementsByTagName("script");

        // Execute each script found
        Array.from(scripts).forEach(script => {
            const scriptElement = document.createElement("script");
            if (script.src) {
                scriptElement.id = script.id;
                scriptElement.src = script.src;
                scriptElement.setAttribute("data-culture", script.dataset.culture);
                scriptElement.async = true;
                setCbConsent(true);
            } else {
                // For inline scripts, use textContent to set script body
                scriptElement.textContent = script.innerText;
            }
            // Append to the container ref instead of body
            if (containerRef.current) {
                containerRef.current.appendChild(scriptElement);
            }
        });

    }, [html, cbConsent, setCbConsent]);

    // Sanitize the HTML before parsing it to components to prevent XSS
    const safeHtml = DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
    const reactElements = parse(safeHtml, {
        replace: (domNode) => {
            if (domNode.type === "script") {
                // Ignore the script tags because they are handled separately
                return <></>;
            }
        }
    });

    return <div ref={containerRef}>{reactElements}</div>;
}

export default HtmlParserAndExecutor;