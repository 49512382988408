import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { map, truncate } from "lodash";
import { Text, Box, Flex, Image } from "rebass";
import { useTranslation } from "react-i18next";

import {
  fetchPaginationPostsIfNeeded,
  setActiveTag,
} from "../containers/Post/Post.action";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const News = ({ background }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const page = params.pageCount || 1;
  const { t } = useTranslation();
  const isFetching = useSelector((state) => state.page.isFetching);
  const tagsList = useSelector((state) => state.entities.tags);
  const activeTag = useSelector((state) => state.post.activeTag);
  const lang = useSelector((state) => state.app.lang);

  const posts = useSelector((state) => {
    return map(state.post.pagination[page], (slug) => {
      return state.entities.posts[slug];
    });
  });

  useEffect(() => {
    dispatch(fetchPaginationPostsIfNeeded(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!posts || posts.length < 1 || isFetching) return null;

  return (
    <Wrapper size="small" bg={background} color={getTextColor(background)}>
      <Container>
        <Flex flexWrap="wrap" pt={5}>
          <Box width={[1, 1, 10 / 12]}>
            {posts
              .filter((post) => {
                if (!activeTag) return true;
                return post.tags.some(
                  (tag) => tagsList[tag] && tagsList[tag].slug === activeTag
                );
              })
              .map(({ acf, slug, tags }, i) => {
                return (
                  <Grid width={1} key={i} pb={9}>
                    <Flex flexWrap="wrap">
                      <Box width={[1, 1, 4 / 12]} pr={3}>
                        <Image
                          pb={[4, 0]}
                          src={acf.feature_image && acf.feature_image.url}
                        />
                      </Box>

                      <Box
                        width={[1, 1, 7 / 12]}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {tags.length > 0 && (
                          <Text variant="h4" pb="1">
                            {tags.map(
                              (tag) =>
                                tagsList[tag] &&
                                tagsList[tag].slug.replace(/-/gi, " ")
                            )}
                          </Text>
                        )}
                        <Text variant="h3" flexGrow={1} pb={2}>
                          {truncate(acf.preview[lang], {
                            length: 150,
                            omission: "...",
                          })}
                        </Text>
                        <Text
                          as={Link}
                          to={"/posts/" + slug}
                          variant="paragraph"
                          fontWeight="bold"
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          {t("read more")}
                        </Text>
                      </Box>
                    </Flex>
                  </Grid>
                );
              })}
          </Box>
          <Grid width={[1, 1, 2 / 12]} pb={9}>
            <Text
              variant="paragraph"
              fontWeight="bold"
              pb={2}
              onClick={() => dispatch(setActiveTag(null))}
              sx={{
                textDecoration: activeTag === null ? "underline" : "none",
                cursor: "pointer",
              }}
            >
              {t("show all")}
            </Text>
            {tagsList &&
              Object.values(tagsList).map(({ slug }, i) => {
                return (
                  <Text
                    key={i}
                    variant="paragraph"
                    fontWeight="bold"
                    pb={2}
                    onClick={() => dispatch(setActiveTag(slug))}
                    sx={{
                      textDecoration: activeTag === slug ? "underline" : "none",
                      cursor: "pointer",
                    }}
                  >
                    {slug.replace(/-/gi, " ")}
                  </Text>
                );
              })}
          </Grid>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default News;
