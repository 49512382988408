import Schemas from "../../api/schemas";
import request from "../../api/request";
import * as types from "./Post.constant";

function requestPost(slug) {
  return {
    type: types.FETCH_POST_REQUEST,
    slug
  };
}

function receivePost(response) {
  return {
    type: types.FETCH_POST_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchPost(slug) {
  return dispatch => {
    dispatch(requestPost(slug));
    return request(`/posts/?slug=${slug}`, Schemas.POST, true).then(json => {
      return dispatch(receivePost(json));
    });
  };
}

function shouldFetchPost(state, slug) {
  const post = state.entities.posts[slug];
  return !(post && post.content && post.acf);
}

export function fetchPostIfNeeded(slug) {
  return (dispatch, getState) => {
    return shouldFetchPost(getState(), slug)
      ? dispatch(fetchPost(slug))
      : Promise.resolve();
  };
}

/*

*/

function requestPosts() {
  return {
    type: types.FETCH_POSTS_REQUEST
  };
}

function receivePosts(response) {
  return {
    type: types.FETCH_POSTS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchPosts(f, { tags, ids, slugs }) {
  return dispatch => {
    dispatch(requestPosts());
    const t = tags ? `tags=${tags}&` : "";
    const i = ids ? `includes=${ids}&` : "";
    const s = slugs ? `slug=${slugs}&` : "";
    const filter = f
      ? `?${t}${i}${s}${f}`
      : `?${i}${i}fields=id,date,slug,status,type,title,link,menu_order,tags,acf`;
    return request(`/posts/${filter}`, Schemas.POST_ARRAY).then(json => {
      return dispatch(receivePosts(json));
    });
  };
}

export function fetchPostsIfNeeded(filter, options) {
  return (dispatch, getState) => {
    if (!getState().post.postsByIds.length || options) {
      return dispatch(fetchPosts(filter, options));
    }
  };
}

/*

*/

function requestSearchedPosts() {
  return {
    type: types.FETCH_SEARCHED_POSTS_REQUEST
  };
}

function receiveSearchedPosts(response) {
  return {
    type: types.FETCH_SEARCHED_POSTS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

export function searchPosts(query) {
  return dispatch => {
    dispatch(requestSearchedPosts());

    return request(
      `/posts/?per_page=30&order=asc&search=${query}`,
      Schemas.POST_ARRAY
    ).then(json => {
      return dispatch(receiveSearchedPosts(json));
    });
  };
}

/*

*/

function requestPaginationPosts() {
  return {
    type: types.FETCH_PAGINATION_POSTS_REQUEST
  };
}

function receivePaginationPosts(response) {
  return {
    type: types.FETCH_PAGINATION_POSTS_SUCCESS,
    response,
    receivedAt: Date.now()
  };
}

function fetchPaginationPosts(f, page = 1) {
  return dispatch => {
    dispatch(requestPaginationPosts());
    const p = `per_page=10&page=${page}&`;
    const filter = f
      ? `?${p}${f}`
      : `?${p}fields=id,date,slug,status,type,title,link,menu_order,acf`;
    return request(`/posts/${filter}`, Schemas.POST_ARRAY).then(json => {
      return dispatch(receivePaginationPosts({ ...json, page }));
    });
  };
}

export function fetchPaginationPostsIfNeeded(filter, page) {
  return (dispatch, getState) => {
    if (!getState().post.pagination[page]) {
      return dispatch(fetchPaginationPosts(filter, page));
    }
  };
}

export function setActiveTag(tag) {
  return dispatch => {
    return dispatch({
      type: types.SET_ACTIVE_TAG,
      response: { tag }
    });
  };
}

export function setActive(path, post, lang) {
  let postTitle = post ? post.acf.title[lang].trim() : '';
  return {
    type: types.SET_POST_ACTIVE,
    response: {
      path,
      title: postTitle
    },
    receivedAt: Date.now()
  };
}