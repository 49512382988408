import React from "react";
import { Box } from "rebass";

const Container = ({ children, ...rest }) => {
  return (
    <Box
      maxWidth={1360}
      height="auto"
      mx={[0, 3, 4, 6, 6, "auto"]}
      // bg={["purple", "green", "red", "blue", "orange", "pink"]}
      flexWrap="wrap"
      mz
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Container;
