import React from "react";
import { Text, Box, Flex, Image, Link } from "rebass";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import ContentPost from "../ui/ContentPost";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const List = ({ blocks }) => {
  const isFetching = useSelector((state) => state.person.isFetching);
  const people = useSelector((state) => state.entities.people);
  const lang = useSelector((state) => state.app.lang);
  if (!people || isFetching) return null;
  return (
    <React.Fragment>
      {blocks.map((block, i) => (
        <Flex key={i} row={i} flexWrap="wrap">
          <Grid width={[1, 1, 1, 1 / 4]}>
            <Text variant="h2">{block.headline}</Text>
          </Grid>
          <Grid width={[1, 1, 1, 3 / 4]}>
            {block.person.map(({ person: p }) => {
              const person = people[p.post_name];
              return (
                person &&
                person.acf && (
                  <Flex
                    key={person.acf.name}
                    width="100%"
                    flexWrap="wrap"
                    pb={[6, 3, 1]}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box width={[1, 2 / 12]}>
                      {person.acf.image && person.acf.image.url && (
                        <Image src={person.acf.image.url} pr={4} />
                      )}
                    </Box>
                    <Box width={[1, 5 / 12]}>
                      <Flex
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Text variant="paragraph" fontWeight="bold">
                            {person.acf.name}
                          </Text>
                          <Text variant="paragraph" fontWeight="bold">
                            {person.acf.title[lang]}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box width={[1, 5 / 12]}>
                      <Text variant="paragraph" fontWeight="bold">
                        <Link href={`mailto:${person.acf.email}`}>
                          {person.acf.email}
                        </Link>
                      </Text>
                      <Text variant="paragraph" fontWeight="bold">
                        <Link href={`tel:${person.acf.phone}`}>
                          {person.acf.phone}
                        </Link>
                      </Text>
                    </Box>
                  </Flex>
                )
              );
            })}
          </Grid>
        </Flex>
      ))}
    </React.Fragment>
  );
};

const GridComponent = ({ blocks, board }) => {
  const isFetching = useSelector((state) => state.person.isFetching);
  const people = useSelector((state) => state.entities.people);
  const lang = useSelector((state) => state.app.lang);

  if (!people || isFetching) return null;
  return (
    <Flex flexWrap="wrap">
      {blocks.map((block, i) => (
        <React.Fragment key={i}>
          {block.person.map(({ person: p }, i) => {
            const person = people[p.post_name];

            if (!person) {
              return null;
            }

            const { acf } = person;

            if (!acf) {
              return null;
            }

            const { alt_image, name, title, email, phone } = acf;
            if (board === "board") {
              return (
                <Grid key={i} width={[1, 1, 1 / 2]}>
                  <BoardMember {...{ acf, lang }} />
                </Grid>
              );
            }
            return (
              <Grid key={i} width={[1, 1, 1 / 2]}>
                <Box pb={2}>
                  {alt_image?.url && <Image src={alt_image.url} />}
                </Box>
                <Box pb={2}>
                  <Text variant="paragraph" fontWeight="bold">
                    {name}
                  </Text>
                  <Text variant="paragraph" fontWeight="bold">
                    {title[lang]}
                  </Text>
                </Box>
                <Box>
                  <Text variant="paragraph" fontWeight="bold">
                    <Link color="inherit" href={`mailto:${email}`}>
                      {email}
                    </Link>
                  </Text>
                  <Text variant="paragraph" fontWeight="bold">
                    <Link color="inherit" href={`tel:${phone}`}>
                      {phone}
                    </Link>
                  </Text>
                </Box>
              </Grid>
            );
          })}
        </React.Fragment>
      ))}
    </Flex>
  );
};

const components = {
  list: List,
  grid: GridComponent,
};

const Contact = ({ blocks = [], background, display = "list", board }) => {
  const Comp = components[display];
  return (
    <Wrapper size={"large"} bg={background} color={getTextColor(background)}>
      <Container>
        <Comp {...{ board, blocks }} />
      </Container>
    </Wrapper>
  );
};

const BoardMember = ({ acf, lang }) => {
  const { name, people_content, alt_image } = acf;
  return (
    <>
      <Box pb={4}>
        {alt_image?.url && (
          <Image sx={{ display: "block" }} src={alt_image.url} />
        )}
      </Box>
      <Text variant="h3" mb={2}>
        {name}
      </Text>
      <ContentPost mb={20} m="20">
        {people_content && people_content[lang] && parse(people_content[lang])}
      </ContentPost>
    </>
  );
};

export default Contact;
