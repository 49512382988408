import React from "react";
import { Text, Box, Flex, Image } from "rebass";
import styled from "styled-components";
import parse from "html-react-parser";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";
import ContentPost from "../ui/ContentPost";
import HtmlParserAndExecutor from "./HtmlParserAndExecutor";

const Headline = styled(Text)`
  text-align: center;
  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 5px;
    display: block;
    background: black;
  }

  &::before {
    margin-bottom: 10px;
  }

  &::after {
    margin-top: 10px;
  }
`;

const Divided = ({ cbConsent, setCbConsent, headline, items = [], size = "full" }) => {
    return (
        <Flex flexWrap="wrap">
            <Grid width={[1, size === "half" ? 2 / 4 : 1 / 3]}>
                <Text variant="h2">{headline}</Text>
            </Grid>
            {size !== "half" && <Grid width={[1, size === "half" ? 1 : 1 / 3]} />}
            <Grid width={[1, size === "half" ? 2 / 4 : 1 / 3]} pb={0} mt={-6}>
                {items.length > 0 && items.map(({ text, text_img, text_code }, i, all) => (
                    <React.Fragment key={i}>
                        {text_img &&
                        <Image
                            mt={6}
                            src={text_img.url}
                        />}
                        <ContentPost>
                            {text_code ? <HtmlParserAndExecutor html={text_code} cbConsent={cbConsent} setCbConsent={setCbConsent}/> : text && parse(text)}
                        </ContentPost>
                    </React.Fragment>
                ))}
            </Grid>
        </Flex>
    )
};

const GridComponent = ({ headline, items = [] }) => (
  <React.Fragment>
    <Grid width={1} pb={7}>
      {headline && headline.length > 0 && (
        <Headline variant="heading">{headline}</Headline>
      )}
    </Grid>
    <Box
      sx={{
        columns: ["1", "2", "3"],
      }}
    >
      {items.length > 0 && items.map((item, i) => {
        return (
          <Grid
            key={i}
            width={1}
            pb={0}
            sx={{
              pageBreakInside: "avoid",
              breakInside: "avoid",
            }}
          >

              {item.text_img &&
                <Image
                  src={item.text_img.url}
                />
              }
              <Text variant="paragraph" fontWeight="bold" mt={item.text_img ? '4': '0'}>
                {item.headline}
              </Text>
              <ContentPost mb={20} m={20}>
                {item.text && parse(item.text)}
              </ContentPost>

          </Grid>
        );
      })}
    </Box>
  </React.Fragment>
);

const components = {
  divided: Divided,
  grid: GridComponent,
};

const TextComp = ({ display, background, ...rest }) => {
  const BlockComponent = components[display];

  return (
    <Wrapper size="xlarge" bg={background} color={getTextColor(background)}>
      <Container>
        <BlockComponent {...rest} />
      </Container>
    </Wrapper>
  );
};

export default TextComp;
