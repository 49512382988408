import React from "react";
import parse from "html-react-parser"
import { Box } from "rebass";
import Grid from "../Grid";
import Wrapper from "../Wrapper";
import Container from "../Container";
import { getTextColor } from "../../helpers";

const Banner = ({ background = "#f48024" }) => {
    const content = `The below information in this Investor Relations section is in Swedish only. According to Sonat Groups 
    information policy, regulatory press releases and financial reports will be published in Swedish.`;
  return (
    <Wrapper
        size="small"
        bg={background}
        pt={0}
        pb={0}
        color={getTextColor(background)}
    >
        <Container>
            <Box 
            backgroundColor={background}
            py={6}
            sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}>
                <Grid 
                width={[1, 1, 9 / 12]}
                sx={{
                    fontSize: "16px",
                    lineHeight: "1.5",
                    margin: "auto"
                }}
                pb={0}
                >
                    {parse(content)}
                </Grid>
            </Box>
        </Container>
    </Wrapper>
  );
};

export default Banner;
