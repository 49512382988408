import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export function getLinkProps(props) {
    if (props?.to && props.to.startsWith('http')) {
        props.href = props.to;
        props.as = "a";
        props.rel = "noopener noreffer";
        props.target = "_blank";
        delete props.to;
    }
    return props;
};

export function getTag (tags, t) {
    const regulatory = tags.filter((tag) => {
      if (tag.slug === "mfn-regulatory") {
        return true;
      }
      return false;
    });
    return regulatory.length >= 1
      ? regulatory.map((tag) => t(tag.name))
      : t("Non regulatory");
};