import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { map } from "lodash";
import { Text, Box, Flex } from "rebass";
import { fetchPaginationMfnsIfNeeded } from "../containers/Mfn/Mfn.action";
import PdfButton from "../ui/parts/PdfButton";
import { getLinkProps } from "../utils";

import Wrapper from "../ui/Wrapper";
import Container from "../ui/Container";
import Grid from "../ui/Grid";
import { getTextColor } from "../helpers";

const Press = ({ title, title_link, how_many_results, background }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const limit = how_many_results ? parseInt(how_many_results) : 100;
  const page = params.pageCount || 1;
  const isFetching = useSelector((state) => state.page.isFetching);
  const lang = useSelector((state) => state.app.lang);

  const mfns = useSelector((state) => {
    return map(state.mfn.pagination[page], (slug) => {
      return state.entities.mfns[slug];
    });
  });

  useEffect(() => {
    dispatch(fetchPaginationMfnsIfNeeded(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!mfns || mfns.length < 1 || isFetching) return null;

  return (
    <Wrapper
      pt={8}
      pb={"60px"}
      size="small"
      bg={background}
      color={getTextColor(background)}
    >
      <Container>
        <Flex
          flexWrap="wrap"
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          {title_link?.url ? (
            <Text
              {...getLinkProps({
                as: Link,
                to: title_link?.url,
                variant: "h2",
                sx: {
                  textTransform: "uppercase",
                  color: "inherit",
                },
                mb: "32px",
                textAlign: "center",
              })}
            >
              {title}
            </Text>
          ) : (
            <Text
              variant="h2"
              textAlign="center"
              sx={{ textTransform: "uppercase" }}
              mb={6}
            >
              {title}
            </Text>
          )}
          {mfns
            .filter((mfn) => {
              const langs = { swe: 16, eng: 17 };
              if (mfn["mfn-news-tag"].some((v) => [langs[lang]].includes(v)))
                return true;
              return false;
            })
            .filter((mfn) => {
              /* 25 is the ID of mfn-report */
              if (mfn["mfn-news-tag"].includes(25)) return true;
              return false;
            })
            .slice(0, limit)
            .map(({ title, attachments, id }, i, row) => {
              return (
                <Grid key={id} width={[1, 1, 9 / 12]} pb={0}>
                  <Flex
                    py={[0, 0, 6, 6]}
                    sx={{
                      flexDirection: ["column", "column", "row", "row"],
                      borderTop: "2px solid rgba(0,0,0,0.2)",
                      borderBottom:
                        i + 1 === row.length ? "2px solid rgba(0,0,0,0.2)" : "",
                    }}
                  >
                    <Box pt={[6, 6, 0, 0]} width={[1, 1, 6 / 12, 9 / 12]}>
                      <Text
                        variant="h3"
                        dangerouslySetInnerHTML={{ __html: title?.rendered }}
                      />
                    </Box>
                    <Box
                      py={[6, 6, 0, 0]}
                      width={[1, 1, 5 / 12, 3 / 12]}
                      sx={{
                        display: "flex",
                        justifyContent: [
                          "flex-start",
                          "flex-start",
                          "flex-end",
                          "flex-end",
                        ],
                        alignItems: "center",
                      }}
                    >
                      <PdfButton
                        link={{
                          url: extractPDF(attachments),
                          title: "Ladda ner PDF",
                        }}
                      />
                    </Box>
                  </Flex>
                </Grid>
              );
            })}
        </Flex>
      </Container>
    </Wrapper>
  );
};

function extractPDF(attachments) {
  const PDF = attachments.filter((attachment) => {
    return attachment.content_type === "application/pdf";
  });
  if (PDF.length > 1) {
    PDF.filter((attachment) => {
      return attachment.tags.includes(":primary");
    });
  }
  if (PDF.length) {
    const { url } = PDF.shift();
    return url;
  }
  /* Did not find any PDF attachment */
  return "";
}

export default Press;
