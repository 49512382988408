import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Box, Text, Image } from "rebass";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Container from "../../ui/Container";
import Grid from "../../ui/Grid";
import styled from "styled-components";

import { fetchPostIfNeeded, setActive } from "./Post.action";
import YoastHead from "../../components/YoastHead";

const VideoWrapper = styled.div`
  > div {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  };
`

const TextComponent = ({ block, lang = "eng" }) => {
  return (
    <Box width={[1, 1, 6 / 12]}>
      <Text variant="paragraph">
        <ReactMarkdown source={block[lang]} />
      </Text>
    </Box>
  );
};

const ImageComponent = ({ block }) => {
  return (
    block &&
    block.image_block && (
      <Box width={[1, 1, 7 / 12]} className="post-text">
        <Image src={block.image_block.url} />
      </Box>
    )
  );
};

const VideoComponent = ({ block }) => {
  const lang = useSelector((state) => state.app.lang);
  const type = block[`video_type_${lang}`]
  const iframe = type === 'youtube' ? block[`oembed_${lang}`] : block[`iframe_${lang}`] ? `<div>${block[`iframe_${lang}`]}</div>`: ""

  return (
    iframe &&
    <Box width={[1, 1, 7 / 12]} className="post-text">
      <VideoWrapper dangerouslySetInnerHTML={{__html:iframe}}/>
    </Box>
  )
};

const components = {
  text: TextComponent,
  image: ImageComponent,
  video: VideoComponent
};

const Post = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const path = params.path;
  const isFetching = useSelector((state) => state.post.isFetching);
  const post = useSelector((state) => state.entities.posts[path]);
  const lang = useSelector((state) => state.app.lang);
  const postTitle = post ? post.acf.title[lang] : 'Sonat';
  const tagsList = useSelector((state) => state.entities.tags);

  useEffect(() => {
    dispatch(fetchPostIfNeeded(path))
      .then(() => dispatch(setActive(path, post, lang)))
      .catch(() => {
        history.push("/news");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  if (isFetching || !post) return <div>loading</div>;

  const { acf, tags, yoast_head } = post;

  return (
    <div className={"post-view"}>
        <Helmet>
            <title>{ postTitle }</title>
        </Helmet>
        <YoastHead json={ yoast_head } />
        <Container>
            <Grid>
            <Text
                variant="paragraph"
                pt={9}
                pb={1}
                sx={{
                textTransform: "uppercase",
                }}
            >
                {tags && tags.map((tag) => tagsList[tag].slug.replace(/-/gi, " "))}
            </Text>
            <Text variant="h2" pb={6}>
                {acf.title[lang]}
            </Text>
            {acf.blocks &&
                acf.blocks.map((block, i) => {
                const Component = components[block.acf_fc_layout];
                return (
                    Component && (
                    <Box key={i} pb={6}>
                        <Component block={block} lang={lang} />
                    </Box>
                    )
                );
                })}
            </Grid>
        </Container>
    </div>
  );
};

export default Post;
