import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { merge } from "lodash";

import app from "../containers/App/App.reducer";
import page from "../containers/Page/Page.reducer";
import post from "../containers/Post/Post.reducer";
import mfn from "../containers/Mfn/Mfn.reducer";
import tag from "../containers/Tag/Tag.reducer";
import person from "../containers/Person/Person.reducer";
import financialCalendar from "../containers/FinancialCalendar/FinancialCalendar.reducer";
import disclaimer from "../containers/Disclaimer/Disclaimer.reducer";
import contactPerson from "../containers/ContactPerson/ContactPerson.reducer";

// Updates an entity cache in response to any action with response.entities.
const entities = (
  state = { pages: {}, posts: {}, tags: {}, people: {}, mfns: {} },
  action
) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }
  return state;
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    entities,
    app,
    page,
    post,
    mfn,
    tag,
    person,
    financialCalendar,
    disclaimer,
    contactPerson,
  });
