import {
  FETCH_CONTACT_PERSON_REQUEST,
  FETCH_CONTACT_PERSON_SUCCESS,
  FETCH_CONTACT_PERSON_FAILURE,
} from "./ContactPerson.constant";

export default function contactPerson(
  state = {
    isFetching: false,
    person: [],
  },
  action = {}
) {
  switch (action.type) {
    case FETCH_CONTACT_PERSON_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case FETCH_CONTACT_PERSON_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        person: action.response.person
      });
    case FETCH_CONTACT_PERSON_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      });
    default:
      return state;
  }
}
