export const FETCH_MFN_REQUEST = "FETCH_MFN_REQUEST";
export const FETCH_MFN_SUCCESS = "FETCH_MFN_SUCCESS";
export const FETCH_MFN_FAILURE = "FETCH_MFN_FAILURE";

export const FETCH_MFNS_REQUEST = "FETCH_MFNS_REQUEST";
export const FETCH_MFNS_SUCCESS = "FETCH_MFNS_SUCCESS";
export const FETCH_MFNS_FAILURE = "FETCH_MFNS_FAILURE";

export const FETCH_SEARCHED_MFNS_REQUEST = "FETCH_SEARCHED_MFNS_REQUEST";
export const FETCH_SEARCHED_MFNS_SUCCESS = "FETCH_SEARCHED_MFNS_SUCCESS";

export const FETCH_PAGINATION_MFNS_REQUEST = "FETCH_PAGINATION_MFNS_REQUEST";
export const FETCH_PAGINATION_MFNS_SUCCESS = "FETCH_PAGINATION_MFNS_SUCCESS";
export const SET_ACTIVE_TAG = "SET_ACTIVE_TAG";

export  const SET_MFN_ACTIVE = 'SET_MFN_ACTIVE'