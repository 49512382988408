import { BREAKPOINTS } from "../containers/App/App.constant";
const h3Mobile = 22;
const h4 = 14;
const h3 = 27;
const h2 = 40;
const h1 = 80;
const p = 16;

const theme = {
  breakpoints: BREAKPOINTS.map((point) => point + "px"), //["576px", "768px", "992px", "1200px"],
  colors: {
    background: "black",
    primary: "#F68023",
    neg: "white",
  },
  fontSizes: [p, h1, h2, h3, h4, h3Mobile],
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 95, 100, 150, 200],
  fonts: {
    body: "Montserrat, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },
  // variants can use custom, user-defined names
  text: {
    heading: {
      fontFamily: "body",
      fontSize: [3, 2, 1],
      lineHeight: "heading",
      fontWeight: "body",
      textTransform: "uppercase",
    },
    h1: {
      fontFamily: "body",
      fontSize: [3, 2, 1],
      lineHeight: "heading",
      fontWeight: "body",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "body",
      fontSize: [3, 2],
      lineHeight: "heading",
      fontWeight: "body",
    },
    h3: {
      fontFamily: "body",
      fontSize: [5, 5, 3],
      lineHeight: "heading",
      fontWeight: "body",
    },
    h4: {
      fontFamily: "body",
      fontSize: [4],
      lineHeight: "body",
      fontWeight: "body",
      textTransform: "uppercase",
    },
    paragraph: {
      fontFamily: "body",
      fontSize: [0],
      lineHeight: "body",
      fontWeight: "body",
    },
    nav: {
      fontFamily: "body",
      fontSize: [4],
      lineHeight: "body",
      fontWeight: "body",
    },
  },
  variants: {
    link: {
      textDecoration: "underline",
      fontWeight: "bold",
    },
    neg: {
      color: "white",
    },
    negLink: {
      textDecoration: "underline",
      color: "white",
      fontWeight: "bold",
    },
  },
  buttons: {
    primary: {
      color: "black",
      bg: "primary",
      fontWeight: "bold",
      borderRadius: 0,
      px: "28px",
      py: "12px",
      cursor: "pointer",
    },
    btnLink: {
      color: "black",
      borderRadius: 0,
      px: 0,
      mx: [2],
      py: 0,
      cursor: "pointer",
      bg: "transparent",
      fontWeight: "normal",
    },
    textLink: {
      color: "black",
      borderRadius: 0,
      px: 0,
      mx: [2],
      py: 0,
      cursor: "pointer",
      bg: "transparent",
      fontWeight: "normal",
      fontFamily: "body",
    },
  },
};

export default theme;
