import React from "react";
import { Box, Text } from "rebass";
import Marquee from "react-double-marquee";

export default props => (
  <Box bg={"#F68023"} sx={{ whiteSpace: "nowrap" }} py={4}>
    <Text variant={"h2"}>
      <Marquee
        direction="left"
        children="THE REGENERATIVE MODEL OF SUPPLY IS CIRCULAR ᛫ "
        speed={0.14}
        delay={0}
        childMargin={0}
      />
    </Text>
  </Box>
);
